import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY } from "../config/constants";
import { routes } from "../config/routes";

class NoAuthRouter extends Component {
  render() {
    const { children, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={({ location }) => {
          if (!localStorage.getItem(LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY)) {
            return (
              <div className="main-container">
                <div className="inner-container">{children}</div>
              </div>
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: routes.BASEPATH,
                  state: { from: location },
                }}
              />
            );
          }
        }}
      />
    );
  }

  componentDidMount() {
    document.querySelector("body").classList.add("blue-bg");
  }
}

export default NoAuthRouter;
