export const CLEAR_ACCEPT_CARD_WRAPPER = "card-wrapper";
export const CLEAR_ACCEPT_DATE_WRAPPER = "expdate-wrapper";
export const CLEAR_ACCEPT_CVV_WRAPPER = "cvv-wrapper";
export const CLEAR_ACCEPT_CARD_ERR_WRAPPER = "card-errors";
export const CLEAR_ACCEPT_DATE_ERR_WRAPPER = "expdate-errors";
export const CLEAR_ACCEPT_CVV_ERR_WRAPPER = "cvv-errors";
export const CLEAR_ACCEPT_PAY_BTN_ID = "pay";

export const CLEAR_ACCEPT_CARD_PLACEHOLDER = "Card Number";
export const CLEAR_ACCEPT_DATE_PLACEHOLDER = "MM / YY";
export const CLEAR_ACCEPT_CVV_PLACEHOLDER = "CVV";
export const CLEAR_ACCEPT_CARD_LOGO_ALIGNMENT = "right";

export const clearAcceptGetFieldsConfig = (optionalConfig) => {
  return {
    card: {
      containerElementId: CLEAR_ACCEPT_CARD_WRAPPER,
    },
    expdate: {
      containerElementId: CLEAR_ACCEPT_DATE_WRAPPER,
    },
    cvv: {
      containerElementId: CLEAR_ACCEPT_CVV_WRAPPER,
    },
    ...optionalConfig,
  };
};

export const clearAcceptGetDefaultStyles = (optionalStyles) => {
  return {
    logoAlignment: CLEAR_ACCEPT_CARD_LOGO_ALIGNMENT,
    placeholderText: {
      cardNumber: CLEAR_ACCEPT_CARD_PLACEHOLDER,
      expiryDate: CLEAR_ACCEPT_DATE_PLACEHOLDER,
      cvv: CLEAR_ACCEPT_CVV_PLACEHOLDER,
    },
    base: {
      borderColor: "#eaecee",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: "25px",
      backgroundColor: "white",
      fontSize: "16px",
      fontColor: "#23313e",
      fontWeight: "normal",
      textAlign: "left",
      padding: "10px 20px",
      focus: {
        borderColor: "#eaecee",
      },
      hover: {
        borderColor: "#eaecee",
      },
    },
    ...optionalStyles,
  };
};

export const clearAcceptGetClearAcceptOptions = (
  optionalOptions,
  optionalFieldsConfig,
  optionalStyles
) => {
  return {
    fields: clearAcceptGetFieldsConfig(optionalFieldsConfig),
    style: clearAcceptGetDefaultStyles(optionalStyles),
    ...optionalOptions,
  };
};
