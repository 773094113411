import React, { Fragment } from 'react';
import Select from 'react-select';

import './SimpleSelect.css';

const SimpleSelect = (props) => {
  const wrapperClasses = ['simple-text-field-wrapper'];
  const labelClasses = ['simple-text-field-label'];
  const errorClasses = ['error-msg'];
  const warningClasses = ['warning-info'];
  if (props.wrapperClasses) {
    wrapperClasses.push(props.wrapperClasses);
  }
  if (props.labelClasses) {
    labelClasses.push(props.labelClasses);
  }
  if (props.errorClasses) {
    errorClasses.push(props.errorClasses);
  }
  if (props.warningClasses) {
    warningClasses.push(props.warningClasses);
  }
  const customStyles = {
    control: (styles, state) => {
      let border = '1px solid #6c7b8a';
      let borderColor = '#6c7b8a';
      if ((props.meta.dirty || props.meta.touched) && props.meta.error) {
        border = '1px solid #fe0000';
        borderColor = '#fe0000';
      }
      return {
        ...styles,
        backgroundColor: '#FFFFFF',
        border,
        borderRadius: '26px',
        lineHeight: '40px',
        padding: '0 20px 0 20px',
        fontSize: '16px',
        boxShadow: 'none',
        ':hover': {
          borderColor,
          boxShadow: 'none',
          cursor: 'pointer'
        },
        ':blur': {
          borderColor,
          boxShadow: 'none'
        },
        ...props.controlStyles
      }
    },
    valueContainer: (styles, state) => {
      return {
        ...styles,
        padding: 0
      }
    },
    singleValue: (styles, state) => {
      return {
        ...styles,
        color: '#2A2D30',
        padding: 0,
        ...props.singleValueStyles
      }
    },
    input: (styles, state) => {
      return {
        ...styles,
        color: '#2A2D30',
        ...props.selectInputStyles
      }
    },
    indicatorSeparator: (styles, state) => {
      return {
        ...styles,
        display: 'none'
      }
    },
    dropdownIndicator: (styles, state) => {
      return {
        ...styles,
        color: '#2A2D30',
        opacity: '0.3',
        ':hover': {
          color: '#2A2D30',
          opacity: '0.3',
        },
        ...props.dropdownIndicatorContainerStyles
      }
    },
    option: (styles, state) => {
      return {
        ...styles,
        backgroundColor: state.isDisabled
          ? null
          : state.isFocused
            ? "#FFBC5B"
            : state.isSelected
              ? "#DDDDDD"
              : null,
        color: state.isDisabled
          ? "#CCC"
          : state.isFocused
            ? "#FFFFFF"
            : "#333333",
        cursor: state.isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: '#FFBC5B',
          color: '#FFFFFF'
        }
      }
    },
    menu: (styles, state) => {
      return {
        ...styles,
        zIndex: 9999
      }
    }
  };
  return (
    <Fragment>
      <div
        className={wrapperClasses.join(' ')}
      >
        {
          props.label && <label
            className={labelClasses.join(' ')}>
            {props.label}
          </label>
        }
        <Select
          // defaultValue={props.selectedValue}
          value={props.input.value || '' }
          onChange={(value) => {
            props.input.onChange(value);
          }}
          options={props.options}
          styles={customStyles}
          noOptionsMessage={() => props.noOptionsMessage ? props.noOptionsMessage : "No options..."}
          isDisabled={typeof props.isDisabled !== 'undefined' ? props.isDisabled : false}
        />
        {
          (props.meta.dirty || props.meta.touched) &&
          (
            (props.meta.error && <p className={errorClasses.join(' ')}>{props.meta.error}</p>) ||
            (props.meta.warning && <p className={warningClasses.join(' ')}>{props.meta.warning}</p>)
          )
        }
      </div>
    </Fragment>
  );
};

export default SimpleSelect;