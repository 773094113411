import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { change, initialize, reset } from "redux-form";
import { withRouter, Link } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';
import DatePicker from "react-datepicker";
import $ from "jquery";
import MyBookingForm from "../MyBookingForm/MyBookingForm";
import MinPaymentForm from "../MinPaymentForm/MinPaymentForm";
import TermsPage from "../TermsPage/TermsPage";
import CardNotApproved from "../CardNotApproved/CardNotApproved"
import {
  findAppointmentTypesRequest,
  findDiaryRequest,
  findDiaryDayRequest,
  findTimeSlotsRequest,
  checkTimeslotsRequest,
  findPaymentCardsRequest
} from "../../../../actions/appointment";
import {
  createInvoiceRequest,
  createReceiptRequest,
  initiatePaymentRequest,
  confirmPaymentRequest,
  findReceiptRequest,
  getConformationRequest,
  bookFinalSlotRequest,
  addAppointmentRequest,
  addInvoiceRequest,
  addReceiptRequest, selectSavedCard
} from "../../../../actions/payment";
import {
  ACCESS_FORBIDDEN,
  DAY_LIST,
  MONTH_LIST,
  SESSION_TIMEOUT
} from "../../../../config/labels";
import { CARD_APPROVED, CLINIC_SESSION_KEY, LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY, LOGGED_USER_SESSION_KEY } from "../../../../config/constants";
import {
  clearAcceptGetClearAcceptOptions,
  CLEAR_ACCEPT_CARD_ERR_WRAPPER,
  CLEAR_ACCEPT_CARD_WRAPPER,
  CLEAR_ACCEPT_CVV_ERR_WRAPPER,
  CLEAR_ACCEPT_CVV_WRAPPER,
  CLEAR_ACCEPT_DATE_ERR_WRAPPER,
  CLEAR_ACCEPT_DATE_WRAPPER,
  CLEAR_ACCEPT_PAY_BTN_ID
} from "../../../../config/clear-accept-payment";
import SimpleImage from "../../../../components/SimpleImage/SimpleImage";
import Card from "../../../../assets/images/card-1.png";
import Help from "../../../../assets/images/help.png";
import Visa from "../../../../assets/images/visa-logo.png";
import MC from "../../../../assets/images/Mastercard-logo.png";
import { routes } from "../../../../config/routes";
import { postRequest } from "../../../../api/clinics-axios";

class MyBooking extends Component {
  constructor(props) {  
    super(props);
    this.state = {
      baseUrl: '',
      appointmentTypes: [],
      diaryArray: [],
      ActiveDates: [],
      selectedClinic: {},
      selectedTime: {},
      selectedTimeSlot: null,
      selectedDate: null,
      formData: {},
      selectedCategory: {},
      amount: 0,
      receiptId: '',
      paymentDetails: {},
      IdInvoice: null,
      checkboxValue: false,
      isFormLoading: false,
      formError: null,
      loadPaymentSchema: false,
      loadingThanyouSchema: false,
      currentIndex: -1
    }
  }

  componentDidMount() {
    if (this.props.selectedFlow === "treatment") {
      this.findAppointmentTypes(this.props.selectedClinic);
      this.setState({ baseUrl: this.props.selectedClinic['client|patient::filepath'] });
    } else {
      postRequest(
        '/layouts/access/_find',
        {
          "query": [
            {
              "z1": "1"
            }
          ],
          "script" : "createSession",
          "script.param" : JSON.parse(localStorage.getItem(LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY)).email,
        },
        "",
        this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
      )      
    }
    window.$('[data-toggle="tooltip"]').tooltip();   
  }

  componentDidUpdate(prevProps) {
    const {
      findAppointmentTypesLoading,
      findDiaryLoading,
      findDiaryDayLoading,
      checkTimeslotsLoading,
      createInvoiceLoading,
      createReceiptLoading,
      initiatePaymentLoading,
      confirmPaymentLoading,
      findReceiptLoading,
      getConformationLoading,
      bookFinalSlotLoading,
      addAppointmentLoading,
      addInvoiceLoading,
      addReceiptLoading,
      findTimeSlotsLoading,
      findAppointmentTypesData,
      findDiaryData,
      findDiaryDayData,
      createInvoiceData,
      createReceiptData,
      initiatePaymentData,
      confirmPaymentData,
      findReceiptData,
      getConformationData,
      bookFinalSlotData,
      addAppointmentData,
      addInvoiceData,
      addReceiptData
    } = this.props;

    if (this.props?.selectedClinic?.['fieldData']?.['ID_Clinic'] && this.props?.selectedClinic?.['fieldData']?.['ID_Clinic'] !== prevProps.selectedClinic?.['fieldData']?.['ID_Clinic']) {
      if (this.props.selectedFlow === "treatment") {
        this.setState({ baseUrl: this.props.selectedClinic['client|patient::filepath'] });
        this.findAppointmentTypes(this.props.selectedClinic);
      }
    }

    if (!findAppointmentTypesLoading && prevProps.findAppointmentTypesLoading !== findAppointmentTypesLoading) {
      if (this.props.selectedFlow === "treatment") {
        this.getAppointments(findAppointmentTypesData);
      }
    }
    if (!findDiaryLoading && prevProps.findDiaryLoading !== findDiaryLoading) {
      this.setDiary(findDiaryData);
    }
    if (!findDiaryDayLoading && prevProps.findDiaryDayLoading !== findDiaryDayLoading) {
      if (this.props.selectedFlow === "treatment") {
        this.setActiveDates(findDiaryDayData);
      }
    }
    if (!checkTimeslotsLoading && prevProps.checkTimeslotsLoading !== checkTimeslotsLoading) {
      if (this.props.selectedFlow === "treatment") {
        this.findTimeSlots();
      }
    }

    if (!createInvoiceLoading && prevProps.createInvoiceLoading !== createInvoiceLoading) {
      if (createInvoiceData && this.props.selectedFlow === "treatment") {

         this.props.dispatch(createReceiptRequest({
          baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
          payload: {
            "query": [
              {
                "z1": "1"
              }
            ],
            "script" : "createReceipt",
            "script.param" : `${localStorage.getItem(CLINIC_SESSION_KEY)}|${createInvoiceData?.['response']?.['scriptResult']}|${this.state.amount}|${localStorage.getItem(LOGGED_USER_SESSION_KEY)}|${this.props.selectedClinic['fieldData']['idClinic']}`
            }
        }));
        this.setState({ IdInvoice: createInvoiceData?.['response']?.['scriptResult'] })
      }
    }

    if (!createReceiptLoading && prevProps.createReceiptLoading !== createReceiptLoading) {
      if (createReceiptData && createReceiptData['response'] && (this.props.selectedFlow === "treatment" || this.props.selectedFlow === "payment")) {
        let savedCardToken = '';
        if (this.props.selectedSavedCard && this.props.selectedSavedCard.PermanentToken) {
          savedCardToken = this.props.selectedSavedCard.PermanentToken;
        }
        this.props.dispatch(initiatePaymentRequest({
          baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
          payload: {
            "query": [
              {
                "z1": "1"
              }
            ],
          
            "script" : "initiatePayment",
            "script.param" : `${createReceiptData['response']['scriptResult']}|${savedCardToken}`
          }
        }))
        this.setState({ receiptId: createReceiptData['response']['scriptResult'] });
      }
    }

    if (!initiatePaymentLoading && prevProps.initiatePaymentLoading !== initiatePaymentLoading) {
      if (initiatePaymentData && (this.props.selectedFlow === "treatment" || this.props.selectedFlow === "payment")) {
        this.setState({ fieldToken: initiatePaymentData['response']['scriptResult'] })
        if (initiatePaymentData && initiatePaymentData['response']['scriptResult']) {
          let savedCardToken = '';
          if (this.props.selectedSavedCard && this.props.selectedSavedCard.PermanentToken) {
            savedCardToken = this.props.selectedSavedCard.PermanentToken;
          }
          this.initHostedFields(initiatePaymentData['response']['scriptResult'], savedCardToken);
        }
      }
    }

    if (!confirmPaymentLoading && prevProps.confirmPaymentLoading !== confirmPaymentLoading) {
      if (confirmPaymentData) {

        if(confirmPaymentData['response']['scriptResult'] === CARD_APPROVED){
          if (this.props.selectedFlow === "treatment") {
            this.props.dispatch(bookFinalSlotRequest({
              baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
              payload: {
                "query": [
                  {
                    "z1": "1"
                  }
                ],    
                "script" : "bookTimeslots",
                "script.param" : `${localStorage.getItem(CLINIC_SESSION_KEY)}|${this.state.selectedTimeSlot?.['fieldData']?.['Date']?.split("/")[1] + "/" + this.state.selectedTimeSlot?.['fieldData']?.['Date']?.split("/")[0] + "/" + this.state.selectedTimeSlot?.['fieldData']?.['Date']?.split("/")[2]}|${this.state.selectedTimeSlot?.['fieldData']?.['ID_Diary']}|${this.state.selectedCategory?.['ID_Product']}|${this.state.selectedCategory?.['length']}|${this.state.selectedTimeSlot?.['fieldData']?.['ID_DiaryTimeslot']}|${this.state.selectedTimeSlot?.['fieldData']?.['Time'].substring(this.state.selectedTimeSlot?.['fieldData']?.['Time']?.lastIndexOf(":"), 0)}|${""}`
              }
              // ${this.state.formData?.category?.value}
              // ${this.state.selectedTreatment?.['fieldData']?.['ID_Product']}

            }))
          } else if (this.props.selectedFlow === "payment") {
            this.props.dispatch(findReceiptRequest({
              "query": [
                {
                  "z1": "1"
                }
              ],
            
              "script" : "findReceipt",
              "script.param" : `${this.props.selectedClinic['fieldData']['idClinic']}|${this.state.receiptId}`
            }))
          }
        } else {
          window.$('#card_not_approved').modal({backdrop: 'static', keyboard: false},'show');
        }

      }
    }
    if (!bookFinalSlotLoading && prevProps.bookFinalSlotLoading !== bookFinalSlotLoading) {
      if (bookFinalSlotData && this.props.selectedFlow === "treatment") {
        this.props.dispatch(addAppointmentRequest({
            "query": [
              {
                "z1": "1"
              }
            ],          
            "script" : "createAppointment",
            "script.param" : `${this.props.selectedClinic['fieldData']['idClinic']}|${localStorage.getItem(LOGGED_USER_SESSION_KEY)}|${this.state.selectedTimeSlot?.['fieldData']?.['Date']}|${this.state.selectedTimeSlot?.['fieldData']?.['Time'].substring(this.state.selectedTimeSlot?.['fieldData']?.['Time']?.lastIndexOf(":"), 0)}|${this.state.formData?.['practioner']?.['label']}|${this.state.selectedCategory?.['length']}|${this.state.selectedCategory?.label?.split("-")?.[0]?.trim()}|${this.state.IdInvoice}|${this.state.selectedTimeSlot?.['fieldData']?.['ID_Appointment']}`
        }))
      }
    }

    if (!addAppointmentLoading && prevProps.addAppointmentLoading !== addAppointmentLoading) {
      if (addAppointmentData && this.props.selectedFlow === "treatment") {
        this.props.dispatch(findReceiptRequest({
          "query": [
            {
              "z1": "1"
            }
          ],
        
          "script" : "findReceipt",
          "script.param" : `${this.props.selectedClinic['fieldData']['idClinic']}|${this.state.receiptId}`
        }))
      }
    }

    if (!addInvoiceLoading && prevProps.addInvoiceLoading !== addInvoiceLoading) {
      if (addInvoiceData && this.props.selectedFlow === "treatment") {
        this.props.dispatch(addReceiptRequest({
          "query": [
            {
              "z1": "1"
            }
          ],
        
          "script" : "createReceipt",
          "script.param" : `${this.props.selectedClinic['fieldData']['idClinic']}|${localStorage.getItem(LOGGED_USER_SESSION_KEY)}|${this.state.amount}|${"Visa"}|${this.state.receiptId}|${this.state.IdInvoice}`
      }))
      }
    }

    if (!addReceiptLoading && prevProps.addReceiptLoading !== addReceiptLoading) {
      if (addReceiptData && (this.props.selectedFlow === "treatment" || this.props.selectedFlow === "payment")) {
        if (this.props.selectedSavedCard && this.props.selectedSavedCard.PermanentToken) {
          if (this.state.paymentDetails?.CvvToken) {
            this.props.dispatch(confirmPaymentRequest({
              baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
              payload: {
                // patientId: this.state.receiptId,
                // authKey: '',
                // isSelected: 0,
                // cvvToken: this.state.paymentDetails?.CvvToken
                "query": [
                  {
                    "z1": "1"
                  }
                ],
                "script" : "confirmpayment",
                "script.param" : `${this.state.receiptId}|${''}|${this.state.checkboxValue ? 1 : 0}|${this.state.paymentDetails?.CvvToken}`
              }
            }))
          } else {
            this.setState({ formError: SESSION_TIMEOUT });
          }
        } else {
          if (this.state.paymentDetails?.TokenId) {
            this.props.dispatch(confirmPaymentRequest({
              baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
              payload: {
                // patientId: this.state.receiptId,
                // authKey: this.state.paymentDetails?.TokenId,
                // isSelected: this.state.checkboxValue ? 1 : 0,
                // cvvToken: ''
                "query": [
                  {
                    "z1": "1"
                  }
                ],
                "script" : "confirmpayment",
                "script.param" : `${this.state.receiptId}|${this.state.paymentDetails?.TokenId}|${this.state.checkboxValue ? 1 : 0}|${''}`
              }
            }))
          } else {
            this.setState({ formError: SESSION_TIMEOUT });
          }
        }
      }
    }

    if (!findReceiptLoading && prevProps.findReceiptLoading !== this.props.findReceiptLoading) {
      if (findReceiptData && (this.props.selectedFlow === "treatment" || this.props.selectedFlow === "payment")) {

        this.props.dispatch(getConformationRequest({
          "query": [
            {
              "z1": "1"
            }
          ],
          "script" : "getConfirmation",
          "script.param" : `${findReceiptData?.['response']?.['data']?.[0]?.['fieldData']?.['idReceipt']}`
        }))
      }
    }
    if (!getConformationLoading && prevProps.getConformationLoading !== getConformationLoading) {
      this.onBooking7();
    }
  }

  initHostedFields = (fieldToken, savedCardToken = '') => {
    const that = this;
    const optionalOptions = {};
    if (savedCardToken) {
      optionalOptions.cvvOnly = true;
    }
    const params = {
      fieldToken: fieldToken,
      options: clearAcceptGetClearAcceptOptions(optionalOptions)
    };
    if (window.HostedFields) {
      window.HostedFields.initialise(
        params.fieldToken,
        params.options,
        function (error, hostedFields) {
          that.setState({ isFormLoading: false });
          if (error) {
            that.setState({ formError: error.message });
            return;
            
          }
          hostedFields.on("change",
            function (formStatus) {
              if (!savedCardToken) {
                const cardErrorContainer = document.getElementById(CLEAR_ACCEPT_CARD_ERR_WRAPPER);
                const expdateErrorContainer = document.getElementById(CLEAR_ACCEPT_DATE_ERR_WRAPPER);
                setValidationStatus(formStatus, formStatus.card, cardErrorContainer);
                setValidationStatus(formStatus, formStatus.expDate, expdateErrorContainer);
              }
              const cvvErrorContainer = document.getElementById(CLEAR_ACCEPT_CVV_ERR_WRAPPER);
              // cvvErrorContainer will not be used when channel is 'MO'
              if (cvvErrorContainer) {
                setValidationStatus(formStatus, formStatus.cvv, cvvErrorContainer);
              }
            });
          function setValidationStatus(formStatus, fieldStatus, input) {
            if (fieldStatus.isValid || (fieldStatus.isPristine && formStatus.isNotSubmited)) {
              input.innerHTML = "";
            } else {
              input.innerHTML = fieldStatus.message;
            }
          }
          const payButton = document.getElementById(CLEAR_ACCEPT_PAY_BTN_ID);
          payButton.addEventListener("click",
            function (event) {
              event.preventDefault();
              const data = {
                accountHolderName: ""
              };
              hostedFields.setData(data);
              hostedFields.tokenize(function (token) {
                that.setState({ paymentDetails: token, loadPaymentSchema: false, loadingThanyouSchema: true });

                if (that.props.selectedFlow === "treatment") {
                  that.props.dispatch(addInvoiceRequest({
                      "query": [
                        {
                          "z1": "1"
                        }
                      ],
                      "script" : "createInvoice",
                      "script.param" : `${that.props.selectedClinic['fieldData']['idClinic']}|${localStorage.getItem(LOGGED_USER_SESSION_KEY)}|${that.state.selectedCategory?.['productPrice']}|${that.state.IdInvoice}|${that.state.amount}|${""}`
                  }))
                } else if (that.props.selectedFlow === "payment") {
                  that.props.dispatch(addReceiptRequest({
                      "query": [
                        {
                          "z1": "1"
                        }
                      ],
                      "script" : "createReceipt",
                      "script.param" : `${that.props.selectedClinic['fieldData']['idClinic']}|${localStorage.getItem(LOGGED_USER_SESSION_KEY)}|${that.state.amount}|${"Visa"}|${that.state.receiptId}|${this.state.IdInvoice}`
                  }))
                }
              });
            });
        });
    } else {
      that.setState({ isFormLoading: false, formError: ACCESS_FORBIDDEN });
    }
  };

  render() {
    const { loadPaymentSchema, loadingThanyouSchema, isFormLoading, formError } = this.state;
    const { selectedSavedCard } = this.props;
    return (
      <div className="modal fade cmn_info my_booking_flow my-booking-modal-wrapper" id="book_flow" tabIndex="-1" role="dialog" aria-labelledby="book_flow" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <MyBookingForm
              selectedClinic={this.props.selectedClinic}
              appointmentTypes={this.state.appointmentTypes}
              diaryArray={this.state.diaryArray}

              onSubmit={this.formSubmitted}
              getPractioner={this.getPractioner}
              getActiveDate={this.getActiveDate}
            />
            <form id="step-booking-3">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <i className="clinic-logo"><SimpleImage src={this.props.selectedClinic?.['fieldData']?.['clinic|patient::logo']} /></i>
                <h2 className="popup-title">Choose a date & time</h2>
                <div className="chose-time">
                  <DatePicker
                    inline={true}
                    dateFormat={"MM/dd/yyyy"}
                    className="toggle-date-picker-booking simple-text-field form-control"
                    autoFocus
                    ref={(r) => {
                      this.component = r;
                    }}
                    dayClassName={(date) => `${this.state.ActiveDates.indexOf(date.getTime()) >= 0 ? 'active-date' : 'disabled-date'} `}
                    onChange={(date) => this.getTimeSlotes(date)}
                  />
                </div>
                <div className="app-det">
                  <p>Your appointment:</p>
                  {this.state.selectedDate ? <h4>{this.renderDate(this.state.selectedDate)}</h4> : null}
                  <span>{this.state.formData?.category?.label}</span>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="cmn-btn" id="proc-booking-3" onClick={() => this.state.selectedDate ? this.openTimepicker(this.state.selectedDate) : null }>Choose a time</button>
                <Link to="#" className="back-btn" id="back-booking-2" onClick={() => this.onBooking2()}>back</Link>
              </div>
            </form>
            <form id="step-booking-4">
              <div className="modal-body time-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <i className="clinic-logo"><SimpleImage src={this.state.selectedClinic.fieldData && this.state.selectedClinic['fieldData']['clinic|patient::logo']} /></i>
                <h2 className="popup-title">Choose a date & time</h2>
                <div className="chose-time">
                  <h2>
                    {this.state.selectedDate ?
                      <span className="lt-arw" onClick={() => { this.chnageDate("-1") }}>‹</span> :
                      <span className="lt-arw">‹</span>
                    }
                    {this.state.selectedDate ? DAY_LIST[new Date(this.state.selectedDate).getDay()] + " " + this.renderDate(this.state.selectedDate).split(",")[1].trim().split(" ")[0] : null} <br />
                    {this.state.selectedDate ? MONTH_LIST[new Date(this.state.selectedDate).getMonth()] + " " + new Date(this.state.selectedDate).getFullYear() : null}
                    {this.state.selectedDate ?
                      <span className="rt-arw" onClick={() => { this.chnageDate("+1") }}>›</span> :
                      <span className="rt-arw">›</span>
                    }
                  </h2>
                </div>
                <div className="time-cnt">
                  {
                    this.props.findTimeSlotsData.map((time, index) => {
                      return (
                        <span
                          key={index}
                          onClick={() => this.setTimeSlot(time) }
                          className={time['fieldData']['Time'] === this.state.selectedTimeSlot?.['fieldData']?.['Time'] ? "active" : ''}
                        >
                          { time['fieldData']['Time'].substring(time['fieldData']['Time'].lastIndexOf(":"), 0)}
                        </span>
                      )
                    })
                  }
                </div>
                { ((this.props.findTimeSlotsLoading ||  this.props.checkTimeslotsLoading)  && !this.props.findTimeSlotsData.length) ? <div className="time-cnt"><p>Loading...</p></div> : null}
                { ( (!this.props.findTimeSlotsLoading && !this.props.checkTimeslotsLoading) && !this.props.findTimeSlotsData.length) ? <div className="time-cnt"><p>{ "No time slots are available"}</p></div> : null}
                <div className="app-det">
                  <p>Your appointment:</p>
                  <h4> {this.state.selectedTimeSlot?.['fieldData']?.['Time'].substring(this.state.selectedTimeSlot?.['fieldData']?.['Time'].lastIndexOf(":"), 0)}  {this.state.selectedTimeSlot?.['fieldData']?.['Time'].substring(this.state.selectedTimeSlot?.['fieldData']?.['Time'].lastIndexOf(":"), 0) ? "," : null} {this.renderDate(this.state.selectedDate)}</h4>
                  <span>{this.state.formData?.category?.label}</span>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="cmn-btn" onClick={() => this.state.selectedTimeSlot ? this.onBooking4() : null}>Book and Pay</button>
                <Link to="#" className="back-btn" id="back-booking-3" onClick={() => this.closeBooking3()}>back</Link>
              </div>
            </form>

            <TermsPage formId={"step-booking-9"} onTermsAccepted={() => this.onTermsAccept()} backFromTerms={() => this.onTermsClose()} selectedClinic={this.props.selectedClinic} terms={this.props.selectedClinic?.['fieldData']?.['client|patient::terms']} />

            <MinPaymentForm
              minPrice={this.props.selectedFlow === "treatment" ? this.state.selectedCategory?.['depositAmount'] : this.props.selectedClinic?.['fieldData']?.['depositAmount']}
              productPrice={this.props.selectedFlow === "treatment" ? this.state.selectedCategory?.['productPrice'] : this.props.selectedClinic?.['fieldData']?.['productPrice']}
              btnId="proc-booking-5"
              canelId="back-4"
              formId="step-booking-5"
              selectedClinic={this.props.selectedClinic}
              onSubmit={this.onBooking5}
              onCancel={() => this.closeBooking5()}
            />

            <form id="step-booking-6">
              <div className="modal-body payment-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                <i className="clinic-logo"><SimpleImage src={this.state.selectedClinic.fieldData && this.state.selectedClinic['fieldData']['clinic|patient::logo']} /></i>
                <h2 className="popup-title">How would you like to pay?</h2>
                <div className="card-payment">
                  <div className={`app-cnt curosor-pointer ${selectedSavedCard === null ? 'active' : ''}`} onClick={() => this.toggleSelectSavedCard()}>
                    <i className="card-ic"><SimpleImage src={Visa} /></i>
                    <h2>Credit or Debit Card<span>Visa, Mastercard</span></h2>
                  </div>
                  {this.props.paymentCardsData && this.props.paymentCardsData.length ? <h2 className="payment-heading">Saved cards</h2> : null}
                  {
                    this.props.paymentCardsData.map((card, index) => {
                      return (<div className={`app-cnt curosor-pointer ${(selectedSavedCard !== null && selectedSavedCard.ID_PaymentCard === card['fieldData'].ID_PaymentCard) ? 'active' : ''}`} key={index} onClick={() => this.toggleSelectSavedCard(card['fieldData'])}>
                        <i className="card-ic"><SimpleImage src={["Visa", "VISA"].indexOf(card['fieldData']['CardType']) > -1 ? Visa :  MC } /></i>
                        <h2>{card['fieldData']['CardNickname']}<span> {card['fieldData']['CardNumber']}</span></h2>
                      </div>)
                    })
                  }
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="cmn-btn" id="proc-booking-6" onClick={() => this.onBooking6()}>Continue & Pay</button>
                <Link to="#" className="back-btn" id="back-5" onClick={() => this.closeBooking6()}>back</Link>
              </div>
            </form>

            <form id="step-booking-7" className="my-book-pay-frm">
              {!loadPaymentSchema && loadingThanyouSchema && <div className="text-center">Loading</div>}
              {loadPaymentSchema &&
                <Fragment>
                  <div className="modal-body payment-body">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                    <i className="clinic-logo"><SimpleImage src={this.props.selectedClinic?.fieldData?.['clinic|patient::logo']} alt="1" /></i>
                    <h2 className="popup-title">Payment</h2>
                    <div className="card-payment payment-proc">

                      { this.props.selectedFlow === "treatment" ?
                        <div className="app-cnt cli-pay">
                          {this.state.formData?.category?.depositAmount ?
                            <h2>{this.state?.formData?.category?.label?.split("-")[0].trim()}<span>Paid £{this.state.amount} / £{this.state?.formData?.category?.productPrice}</span></h2> :
                            <h2>{this.state?.formData?.category?.label?.split("-")[0].trim()}<span>Paid £{this.state.amount}</span></h2>
                          }
                          <h4>£{this.state.selectedCategory?.['productPrice']}<span>{this.state.selectedCategory?.['productPrice'] > this.state.amount ? "Part payment" : "Full payment"} </span></h4>
                        </div> : null }
                        { this.props.selectedFlow === "payment" ?    
                        <div className="app-cnt cli-pay">
                          {this.props.selectedClinic?.fieldData?.depositAmount ?
                            <h2>{this.props.selectedClinic?.fieldData?.z_clientClinicName}<span>Paid £{this.state.amount} / £{this.props?.selectedClinic?.fieldData?.productPrice}</span></h2> :
                            <h2>{this.props.selectedClinic?.fieldData?.z_clientClinicName}<span>Paid £{this.state.amount}</span></h2>
                          }
                          <h4>£{this.props.selectedClinic?.fieldData?.['productPrice']}<span>{this.props?.selectedClinic?.fieldData?.['productPrice'] > this.state.amount ? "Part payment" : "Full payment"} </span></h4>
                        </div> : null
                      }
                      {isFormLoading && <div className="form-loading text-center">Loading</div>}
                      {!isFormLoading && formError && <div className="form-error text-center">{formError}</div>}
                      <Fragment>
                        <div
                          className={`app-cnt cards-cnt ${(!isFormLoading && !formError) ? '' : 'd-none'}`}
                        >
                          <i className="card-ic"><SimpleImage src={Card} /></i>
                          <h2>Primary or Debit Card<span>Visa, Mastercard</span></h2>
                        </div>

                        <div
                          className={`cmn-form ${(!isFormLoading && !formError) ? '' : 'd-none'}`}
                        >
                          <div className="simple-text-field-wrapper form-group">
                            <label className="simple-text-field-label">Card number</label>
                            {
                              selectedSavedCard && selectedSavedCard.CardNumber
                                ?
                                <Fragment>
                                  <div style={{ height: '45px' }} className="saved-card-input-wrap">
                                    <input disabled readOnly={true} value={selectedSavedCard.CardNumber} />
                                  </div>
                                </Fragment>
                                :
                                <Fragment>
                                  <div id={CLEAR_ACCEPT_CARD_WRAPPER} style={{ height: '45px' }}></div>
                                  <div id={CLEAR_ACCEPT_CARD_ERR_WRAPPER}></div>
                                </Fragment>
                            }
                          </div>

                          <div className="form-row">
                            <div className="form-group">
                              <label className="simple-text-field-label">Expiry date</label>
                              {
                                selectedSavedCard && selectedSavedCard.ExpiryMonth && selectedSavedCard.ExpiryYear
                                  ?
                                  <Fragment>
                                    <div style={{ height: '45px' }} className="saved-card-input-wrap">
                                      <input disabled readOnly={true} value={`${selectedSavedCard.ExpiryMonth}/${selectedSavedCard.ExpiryYear}`} className="date" />
                                    </div>
                                  </Fragment>
                                  :
                                  <Fragment>
                                    <div id={CLEAR_ACCEPT_DATE_WRAPPER} style={{ height: '45px' }}></div>
                                    <div id={CLEAR_ACCEPT_DATE_ERR_WRAPPER}></div>
                                  </Fragment>
                              }
                            </div>

                            <div className="form-group">
                              <label className="simple-text-field-label">CVV</label>
                              <Fragment>
                                <div id={CLEAR_ACCEPT_CVV_WRAPPER} style={{ height: '45px', width: '75%' }}></div>
                                <div id={CLEAR_ACCEPT_CVV_ERR_WRAPPER}></div>
                              </Fragment>
                              <a data-toggle="tooltip" title="The CVV is the 3 digit security code on the back of your card" className="help-ic cursor-pointer"><SimpleImage src={Help} alt="" /></a>
                            </div>
                          </div>

                          {!selectedSavedCard &&
                            <div className="chckbox mt-0">
                              <input id="remember_me1" type="checkbox" name="checkbox" onChange={(evnt) => this.setState({ checkboxValue: !this.state.checkboxValue })} />
                              <label htmlFor="remember_me1"><span></span><em>Save card details</em></label>
                            </div>
                          }
                        </div>
                      </Fragment>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className={`cmn-btn ${(isFormLoading || formError) ? 'd-none' : ''}`}
                      id={CLEAR_ACCEPT_PAY_BTN_ID}
                      disabled={isFormLoading || formError}
                    >Pay Now</button>
                    <Link to="#" className="back-btn" id={this.props.cancelId} onClick={() => this.closeBooking7()}>back</Link>
                  </div>
                </Fragment>
              }
            </form>

            <form id="step-booking-8">
              <div className="modal-body thank-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                <h2 className="popup-title">Thank you for your booking</h2>
                <p className="txt">You will recieve an email confirmation shortly at:<br />{this.props.getConformationData?.['fieldData']?.['patient|receipt::email']}</p>
                <div className="bill-pay-inner mCustomScrollbar">
                  <div className="order-details">
                    <div className="ord-cnt"><h4>Order number:</h4> <span>{this.props.getConformationData?.['fieldData']?.['ID_Invoice']}</span></div>
                    <div className="ord-cnt"><h4>Ordered on:</h4> <span>{this.renderDate(new Date(this.props.getConformationData?.['fieldData']?.['date']))}</span></div>
                  </div>
                  <div className="pay-sum">
                    <h2>Order summary</h2>
                    <div className="pay-cnt">
                      <h4>{this.props.getConformationData?.['fieldData']?.['clinic|receipt::z_clientClinicName']}</h4>
                      <strong>£{this.props.getConformationData?.['fieldData']?.['amount']}</strong>
                    </div>
                    <div className="app-blk add-clinic-cnt order-summ">
                      <h4>{this.state?.formData?.category?.label?.split("-")[0].trim()}</h4>
                      <div className="add-cal">
                        <i><SimpleImage src={this.props.getConformationData?.['fieldData']?.['clinic|receipt::logo']} /></i>
                        <h4>{this.props.getConformationData?.['fieldData']?.['clinic|receipt::z_clientClinicName']}</h4>
                        <p>{this.getClinicAddress()}</p>
                        <Link to="#" className="cmn-btn edit-btn add-btn">Add to calendar</Link>
                      </div>
                    </div>
                  </div>
                  <div className="pay-sum">
                    <h2>Billing and payment</h2>
                    <div className="bill-cnt">
                      <h4>Bill to:</h4>
                      <h6>
                        {this.props.getConformationData?.['fieldData']?.["patient|receipt::nameFirst"] + " " + this.props.getConformationData?.['fieldData']?.["patient|receipt::nameLast"]}
                        <br />{this.props.getConformationData?.['fieldData']?.['patient|receipt::email']}</h6>
                    </div>
                    <div className="bill-cnt">
                      <h4>Billing address:</h4>
                      <h6> {this.getBillingAddress()}</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer thank-footer">
                <p>Order Total</p>
                <h2>£{this.props.getConformationData?.['fieldData']?.['amount']}</h2>
                <button type="button" className="cmn-btn edit-btn" onClick={() => this.backToDashboard()}>Back to dashboard</button>
                <button onClick={() => this.goToAppointments()} className="cmn-btn">View Appointments</button>
                <div className="chckbox" onClick={() => window.$('#text_reminder').modal('show')}>
                  <input id="remember_me2" type="checkbox" name="checkbox" value="1" />
                  <label htmlFor="remember_me2"><span></span><em>Send text reminder</em></label>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="modal fade cmn_info text-reminder-modal" id="text_reminder" tabIndex="-1" role="dialog" aria-labelledby="text_reminder" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content part-content">
              <div className="modal-body">
                <button type="button" className="close" onClick={() => window.$('#text_reminder').modal('hide')}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h2 className="popup-title">Text reminder</h2>
                <p>We'll send you a text reminder the day before your appointment</p>
                {this.state?.selectedClinic?.['fieldData']?.['clinic|patient::phone'] ? <input type="text" value={this.state?.selectedClinic?.['fieldData']?.['clinic|patient::phone']} onChange={() => { }} className="form-control" /> : null}
                <p>To make any changes to your booking please call the practise 48 hours before your visit on:<br /> 01202 674 354</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="cmn-btn" onClick={() => window.$('#text_reminder').modal('hide')}>Confirm</button>
              </div>
            </div>
          </div>
        </div>
        <CardNotApproved backToCards={() => this.backToCards()} />
      </div>
    )
  }

  backToCards = () =>{
    $('#step-booking-7').slideUp(500);
    $('#step-booking-6').slideDown(500);
    window.$('#card_not_approved').modal('hide');
  }

  setTimeSlot = ( time ) =>{

    let time_stramp = new Date(time['fieldData']['Date']);
    time_stramp.setHours(time['fieldData']['Time'].split(":")[0]);
    time_stramp.setMinutes(time['fieldData']['Time'].split(":")[1]);

    if(+new Date() < +time_stramp ){
      this.setState({ selectedTimeSlot: time })
    } else {
      this.setState({ selectedTimeSlot: null })
    }

  }

  getClinicAddress = () => {
    const clinic = this.props.selectedClinic?.['fieldData'];
    let fullAddress = clinic?.['clinic|patient::address1'] ? clinic?.['clinic|patient::address1'] : '';
    fullAddress += clinic?.['clinic|patient::addressTown'] ? `, ${clinic?.['clinic|patient::addressTown']}` : '';
    fullAddress += clinic?.['clinic|patient::addressPostcode'] ? `, ${clinic?.['clinic|patient::addressPostcode']}` : '';
    return fullAddress;
  }

  getBillingAddress = () => {
    const address = this.props?.getConformationData?.['fieldData'];
    let fullBillAddress = address?.['patient|receipt::address1'] ? address?.['patient|receipt::address1'] : '';
    fullBillAddress += address?.['patient|receipt::addressTown'] ? `, ${address?.['patient|receipt::addressTown']}` : '';
    fullBillAddress += address?.['patient|receipt::addressCounty'] ? `, ${address?.['patient|receipt::addressCounty']}` : '';
    fullBillAddress += address?.['patient|receipt::addressPostcode'] ? `, ${address?.['patient|receipt::addressPostcode']}` : '';
    return fullBillAddress;
  }
  
  backToDashboard = () => {
    this.props.getPatientAppointments();
    window.$(".my_booking_flow").modal('hide');
    this.props.history.push(routes.BASEPATH);
  }

  goToAppointments = () => {
    this.props.getPatientAppointments();
    window.$(".my_booking_flow").modal('hide');
    this.props.history.push(routes.APPOINTMENTS);
  }

  closeBooking3 = () => {
    this.setState({ selectedTimeSlot: null });
    $('#step-booking-4').slideUp(500);
    $('#step-booking-3').slideDown(500);
  }

  closeBooking5 = () => {
    $('#step-booking-5').slideUp(500);
    if (this.props.selectedFlow === "payment") {
      $('#step-booking-9').slideDown(500);
    } else {
      $('#step-booking-4').slideDown(500);
    }
    this.props.dispatch(reset("min-payment-form"))
  }

  closeBooking6 = () => {
    $('#step-booking-6').slideUp(500);
    if (this.props.selectedFlow === "payment") {
      if (this.props.selectedClinic?.['fieldData']?.['depositAmount']) {
        $('#step-booking-5').slideDown(500);
      } else {
        $('#step-booking-4').slideDown(500);
      }
    } else {
      if (this.state.formData?.category?.depositAmount) {
        $('#step-booking-5').slideDown(500);
      } else {
        $('#step-booking-3').slideDown(500);
      }
    }
  }

  closeBooking7 = () => {
    this.setState({ loadPaymentSchema: false, loadingThanyouSchema: false, isFormLoading: false, formError: null });
    $('#step-booking-7').slideUp(500);
    $('#step-booking-6').slideDown(500);
  }

  closeBooking3 = () => {
    $('#step-booking-4').slideUp(500);
    $('#step-booking-3').slideDown(500);
  }

  openTimepicker = () => {
    
    if(this.props.findDiaryDayData[this.state.currentIndex]){

      this.props.dispatch(
        checkTimeslotsRequest({
          baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
          query: {
            "query": [
              {
                "z1": "1"
              }
            ],
            "script" : "AvailableTimeSetter",
            "script.param" : String(this.props.findDiaryDayData[this.state.currentIndex]['fieldData']['ID_DiaryDay'])
          }
        })
      )
    }
    
    this.setState({ selectedTime: null });

    $('#step-booking-3').slideUp(500);
    $('#step-booking-4').slideDown(500);
  }

  onBooking2 = () => {
    $('#step-booking-3').slideUp(500);
    $('#step-booking-2').slideDown(500);
    this.setState({ selectedDate: null });
  }

  onBooking4 = () => {
    $('#step-booking-4').slideUp(500);
    $('#step-booking-9').slideDown(500);
  }

  onTermsAccept = () => {
    $('#step-booking-9').slideUp(500);

    if (this.props.selectedClinic?.fieldData?.['ID_Patient']) {
      this.props.dispatch(findPaymentCardsRequest({
        baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
        query: {
          "query": [
            {
              "z1": "1"
            }
          ],
        
          "script" : "findCard",
          "script.param" : `${this.props.selectedClinic?.fieldData?.['ID_Patient']}`
        }
      }))
    }


    if (this.props.selectedFlow === "payment") {
      $('#step-booking-9').slideUp(500);
      if (this.props.selectedClinic?.['fieldData']?.['depositAmount']) {
        $('#step-booking-5').slideDown(500);
      } else {
        $('#step-booking-6').slideDown(500);
        this.setState({ amount: this.props.selectedClinic?.['fieldData']?.['productPrice'] })
      }
    } else {
      if (this.state.formData?.category?.depositAmount) {
        $('#step-booking-5').slideDown(500);
      } else {
        $('#step-booking-6').slideDown(500);
        this.setState({ amount: this.state.formData?.category?.label.split("£")[1] })
      }
    }
  }

  onTermsClose = () => {
    if (this.props.selectedFlow === "payment") {
      window.$(".my_booking_flow").modal('hide');
      this.props.closePayment();
    } else {
      $('#step-booking-4').slideDown(500);
      $('#step-booking-9').slideUp(500);
    }
  }

  onBooking5 = (data) => {
    this.setState({ amount: data.amount });
    $('#step-booking-5').slideUp(500);
    $('#step-booking-6').slideDown(500);
  }

  onBooking6 = () => {
    this.setState({ loadPaymentSchema: true, loadingThanyouSchema: false }, () => {
      this.setState({ isFormLoading: true, formError: null });
      $('#step-booking-6').slideUp(500);
      $('#step-booking-7').slideDown(500);

      if (!this.state.baseUrl) {
        this.setState({ baseUrl: this.props.selectedClinic['fieldData']['client|clinic::filepath'] })
      }

      if (this.props.selectedFlow === "payment") {
        this.props.dispatch(createReceiptRequest({
          baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
          payload: {
            "query": [
              {
                "z1": "1"
              }
            ],
            "script" : "createReceipt",
            "script.param" : `${localStorage.getItem(CLINIC_SESSION_KEY)}|${this.props?.['selectedClinic']?.['fieldData']?.['receiptId']}|${this.state.amount}|${localStorage.getItem(LOGGED_USER_SESSION_KEY)}|${this.props.selectedClinic['fieldData']['idClinic']}`
          }
        }))
        this.setState({ IdInvoice: this.props?.['selectedClinic']?.['fieldData']?.['receiptId'] })
      } else {
        this.props.dispatch(createInvoiceRequest({
          baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
          payload: {
              "query": [
                {
                  "z1": "1"
                }
              ],
              "script" : "createInvoice",
              "script.param" : `${localStorage.getItem(CLINIC_SESSION_KEY)}|${this.state.formData?.category['ID_Product']}|${this.props.selectedClinic['fieldData']['idClinic']}`
            }
        }))
      }
    });
  }

  onBooking7 = (data) => {
    this.setState({ loadingThanyouSchema: false });
    $('#step-booking-7').slideUp(500);
    $('#step-booking-8').slideDown(500);
  };

  renderDate(renderDate) {
    if (renderDate) {
      return DAY_LIST[renderDate.getDay()] + ", "
        + renderDate.getDate() + `${renderDate.getDate() === 1 ? "st " : renderDate.getDate() === 2 ? "nd " : "th "}`
        + MONTH_LIST[renderDate.getMonth()] + ", "
        + renderDate.getFullYear();
    }
  }

  formSubmitted = (data) => {
    this.setState({ formData: data, selectedDate: null });
    this.openDatePicker();
    $('#step-booking-2').slideUp(500);
    $('#step-booking-3').slideDown(500);
  }

  findAppointmentTypes = (data) => {

    let fullAddress = this.props.selectedClinic?.['fieldData']?.['clinic|patient::address1'] ? this.props.selectedClinic?.['fieldData']?.['clinic|patient::address1'] : '';
    fullAddress += this.props.selectedClinic?.['fieldData']?.['clinic|patient::addressTown'] ? `, ${this.props.selectedClinic?.['fieldData']?.['clinic|patient::addressTown']}` : '';
    fullAddress += this.props.selectedClinic?.['fieldData']?.['clinic|patient::addressPostcode'] ? `, ${this.props.selectedClinic?.['fieldData']?.['clinic|patient::addressPostcode']}` : '';
    this.props.dispatch(initialize("my-booking-form", { location: fullAddress }));

    postRequest(
      '/layouts/access/_find',
      {
        "query": [
          {
            "z1": "1"
          }
        ],
        "script" : "createSession",
        "script.param" : JSON.parse(localStorage.getItem(LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY)).email,
      },
      "",
      this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
    ).then((response) => {

      if(response?.['messages']?.['0']?.['code']){

        this.setState({ baseUrl: data?.fieldData?.['client|patient::filepath'], selectedClinic: data })
        const payload = {
          baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
          query: {
            "query": [
              {
                "z1": "1"
              }
            ],
          
            "script" : "displayTreatments",
            "script.param" : `${response['response']['scriptResult']}|${data?.['fieldData']['idClinic']}`
          }
        }
        this.props.dispatch(findAppointmentTypesRequest(payload))
      }
    })
  }

  openDatePicker = () => {
    this.component.setOpen(true);
  }

  getAppointments = data => {
    let newState = [];

    
    data.map((element, index) => {
      newState.push({
        label: element.fieldData['Product'] + " - " + element.fieldData['AppointmentLength'] + " minutes - £" + element.fieldData['UnitPrice'],
        value: element.fieldData['ID_ProductCategory'],
        depositAmount: element.fieldData['DepositAmount'],
        productPrice: element.fieldData['UnitPrice'],
        length: element.fieldData['AppointmentLength'],
        ID_Product: element.fieldData['ID_Product']
      })
    })
    this.setState({ appointmentTypes: newState });
  }

  getPractioner = (data) => {

    this.props.dispatch(change('my-booking-form','practioner', null ));

    this.setState({ selectedCategory: data })
    if (data) {

      this.props.dispatch(findDiaryRequest({
        baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
        query: {
          "query": [
            {
              "z1": "1"
            }
          ],
          "script" : "findDiary",
          "script.param" : `${this.state['selectedClinic']?.['fieldData']['clinic|patient::ID_Clinic']}|=${data.value}`
        }
      }))
    }
  }

  setDiary = (data) => {
    let newState = [];
    data.map((element) => {

      newState.push({
        label: element.fieldData["User|Diary::NameUserFullNoTitle"],
        value: element.fieldData['ID_Diary']
      })
    })
    this.setState({ diaryArray: newState });
  }

  getActiveDate = data => {
    if (data) {
      this.props.dispatch(findDiaryDayRequest({
        baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
        query: {
          "query": [
            {
              "z1": "1"
            }
          ],
        
          "script" : "findDiaryDays",
          "script.param" : `${">=" + (+new Date().getMonth() + 1) + "/" + (+new Date().getDate()) + "/" + new Date().getFullYear()}|${data.value}`
        }
      }))
    }
  }

  setActiveDates = dates => {
    let newState = []
    dates.map((element) => {
      newState.push(new Date(element.fieldData.Date).getTime())
    })
    this.setState({ ActiveDates: newState });
  }

  chnageDate = (type) => {
    this.setState({ selectedTimeSlot: null });
    if (type === "+1") {
      this.setState({ selectedDate: this.state.ActiveDates[+this.state.currentIndex + 1] ? new Date(this.state.ActiveDates[+this.state.currentIndex + 1]) : this.state.selectedDate, currentIndex: +this.state.currentIndex + 1 },
          () => {  this.openTimepicker(); })
    } else {
      this.setState({ selectedDate: this.state.ActiveDates[+this.state.currentIndex - 1] ? new Date(this.state.ActiveDates[+this.state.currentIndex - 1]) : this.state.selectedDate, currentIndex: +this.state.currentIndex - 1 },
          () => {  this.openTimepicker(); })
    }
  }


  // chnageDate = (type) => {
  //   this.setState({ selectedTimeSlot: null });
  //   if (type === "+1") {
  //     this.setState({ selectedDate: this.state.ActiveDates[+this.state.currentIndex + 1] ? new Date(this.state.ActiveDates[+this.state.currentIndex + 1]) : this.state.selectedDate, currentIndex: +this.state.currentIndex + 1 })
  //     this.openTimepicker();
  //   } else {
  //     this.setState({ selectedDate: this.state.ActiveDates[+this.state.currentIndex - 1] ? new Date(this.state.ActiveDates[+this.state.currentIndex - 1]) : this.state.selectedDate, currentIndex: +this.state.currentIndex - 1 })
  //     this.openTimepicker();
  //   }
  // }

  getTimeSlotes = (date) => {
    this.setState({ selectedDate: date , currentIndex: this.state.ActiveDates.indexOf(new Date(date).setHours(0, 0, 0, 0)) }) 
  }

  findTimeSlots = () => {
    this.props.dispatch(
      findTimeSlotsRequest({
        baseUrl: this.props.selectedClinic?.fieldData?.['client|patient::filepath'],
        query: {
          "query": [
            {
              "z1": "1"
            }
          ],
        
          "script" : "findTimes",
          "script.param" : `${this.props?.findDiaryDayData?.[this.state.currentIndex]?.['fieldData']?.['ID_DiaryDay']}|>=${this.state.selectedCategory?.['length']}`
        }
      })
    )
  }

  toggleSelectSavedCard = (savedCard = null) => {
    this.props.dispatch(selectSavedCard(savedCard));
  }
}

const mapStateToProps = (state) => {
  const { appointment, payment } = state;
  return {
    findAppointmentTypesLoading: appointment.get('findAppointmentTypesLoading'),
    findAppointmentTypesData: appointment.get('findAppointmentTypesData'),

    findDiaryLoading: appointment.get('findDiaryLoading'),
    findDiaryData: appointment.get('findDiaryData'),

    findDiaryDayLoading: appointment.get('findDiaryDayLoading'),
    findDiaryDayData: appointment.get('findDiaryDayData'),

    findTimeSlotsLoading: appointment.get('findTimeSlotsLoading'),
    findTimeSlotsData: appointment.get('findTimeSlotsData'),

    checkTimeslotsLoading: appointment.get('checkTimeslotsLoading'),
    checkTimeslotsData: appointment.get('checkTimeslotsData'),

    paymentCardsLoading: appointment.get('paymentCardsLoading'),
    paymentCardsData: appointment.get('paymentCardsData'),

    createInvoiceLoading: payment.get('createInvoiceLoading'),
    createInvoiceData: payment.get('createInvoiceData'),

    createReceiptLoading: payment.get('createReceiptLoading'),
    createReceiptData: payment.get('createReceiptData'),

    initiatePaymentLoading: payment.get('initiatePaymentLoading'),
    initiatePaymentData: payment.get('initiatePaymentData'),

    confirmPaymentLoading: payment.get('confirmPaymentLoading'),
    confirmPaymentData: payment.get('confirmPaymentData'),

    findReceiptLoading: payment.get('findReceiptLoading'),
    findReceiptData: payment.get('findReceiptData'),

    getConformationLoading: payment.get('getConformationLoading'),
    getConformationData: payment.get('getConformationData'),

    bookFinalSlotLoading: payment.get('bookFinalSlotLoading'),
    bookFinalSlotData: payment.get('bookFinalSlotData'),

    addAppointmentLoading: payment.get('addAppointmentLoading'),
    addAppointmentData: payment.get('addAppointmentData'),

    addInvoiceLoading: payment.get("addInvoiceLoading"),
    addInvoiceData: payment.get("addInvoiceData"),

    addReceiptLoading: payment.get("addReceiptLoading"),
    addReceiptData: payment.get("addReceiptData"),

    selectedSavedCard: payment.get("selectedSavedCard"),
  }
}

MyBooking = withRouter(MyBooking);
export default connect(mapStateToProps)(MyBooking);
