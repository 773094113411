import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector, initialize } from 'redux-form';
import { connect } from 'react-redux';

import TextField from '../../../../components/SimpleTextField/TextField';
import { required, minValue } from '../../../../helpers/validators';
import Pound from "../../../../assets/images/pound-ic.png";
import SimpleImage from '../../../../components/SimpleImage/SimpleImage';
import { Link } from 'react-router-dom';

const requiredMMAMT = required("minimum amount");
const selector = formValueSelector('min-payment-form');

class MinPaymentForm extends Component {

    constructor(props){
        super(props);
        this.state ={
            minValueCondition: minValue('Amount', 0, 0, 0),
            selected: 'max'
        }
    }

    componentDidMount(){
        if(this.props.productPrice){
            this.selectedMax()
        }
    }

    componentDidUpdate(prevProps){

        if (prevProps.amount !== this.props.amount) {
            this.setState({ 
                minValueCondition: minValue( 'Amount', this.props.minPrice, this.props.productPrice, this.props.amount),
            })
        } 
        if(this.props.productPrice !== undefined && prevProps.productPrice !== this.props.productPrice){
            this.selectedMax()
        }
    }

    selectedMax = () =>{
        this.setState({ 
            selected: 'max' ,
            minValueCondition: minValue('Amount', this.props.minPrice, this.props.minPrice)
        })
        this.props.dispatch(initialize("min-payment-form", { amount: String(this.props.productPrice) }))
    }

    selectedMin = () =>{
        this.setState({ 
            selected: 'min' ,
            minValueCondition: minValue('Amount', this.props.minPrice, this.props.minPrice)
        })
        this.props.dispatch(initialize("min-payment-form", { amount: String(this.props.minPrice) }))
    }

    validateInput = (value) =>{
        var regex = /^[0-9]*(\.[0-9]{0,2})?$/;
        return !regex.test(value);
    }

    render(){
        return(        

            <form id={this.props.formId} onSubmit={this.props.handleSubmit}>
                <div className="modal-body payment-body">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                    <i className="clinic-logo"><SimpleImage src={this.props.selectedClinic?.fieldData?.['clinic|patient::logo']} alt="1" /></i>
                    <h2 className="popup-title">How much would you like to pay?</h2>
                    <p>Remaining Balance</p>
                    <h6>£{this.props.productPrice}</h6>
                    <div className="chose-clinic payment-options"> 
                        <Link to="#" className={`part-pay ${this.state.selected === 'max' ? 'active' : ''}`} onClick={() => this.selectedMax()}> Pay In Full <strong>£{this.props.productPrice}</strong> </Link> 
                        <Link to="#" className={`part-pay ${this.state.selected === 'min' ? 'active' : ''}`} onClick={() => this.selectedMin()}> Part Payment <strong>£{this.props.minPrice}<small>Minimum</small></strong> </Link> 
                    </div>
                    <div className="form-group amt-group">
                        <label>Enter Amount</label>
                        { this.state.selected === 'max' ?
                         <Field
                            id={this.props.amount}
                            name="amount"
                            type="amount"
                            component={TextField}
                            validate={ [requiredMMAMT, this.state.minValueCondition]}
                            onChange={(event) =>{ if(this.validateInput(event.target.value)){ event.preventDefault() } }}
                            wrapperClasses={'form-group'}
                        /> :
                        <Field
                            id={this.props.amount}
                            name="amount"
                            type="amount"
                            component={TextField}
                            validate={ [requiredMMAMT, this.state.minValueCondition]}
                            onChange={(event) =>{ if(this.validateInput(event.target.value)){ event.preventDefault() } }}
                            wrapperClasses={'form-group'}
                        />
                        }
                        <i className="pound-ic"><SimpleImage src={Pound} /></i>
                        </div>
                    </div>
                <div className="modal-footer">
                    <button type="submit" className="cmn-btn" id={this.props.btnId}>Continue & Pay</button>
                    <Link to="#" className="back-btn" id={this.props.canelId} onClick={() => this.props.onCancel()}>back</Link> </div>
            </form>
        )
    }
}


MinPaymentForm = reduxForm({
  form: "min-payment-form",
})(MinPaymentForm);
  
const mapStateToProps = state => {
  const amount = selector(state, 'amount');
    return {
       amount
    };
};
  
export default connect(mapStateToProps)(MinPaymentForm);
