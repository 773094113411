const GET_NOTIFICATIONS = "GET/NOTIFICATIONS";

export const GET_NOTIFICATIONS_REQUEST = `${GET_NOTIFICATIONS}/REQUEST`;
export const GET_NOTIFICATIONS_SUCCESS = `${GET_NOTIFICATIONS}/SUCCESS`;
export const GET_NOTIFICATIONS_ERROR = `${GET_NOTIFICATIONS}/ERROR`;

export function getNotificationsRequest(payload) {
  return {
    type: GET_NOTIFICATIONS_REQUEST,
    payload
  };
}

export function getNotificationsSuccess(data) {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    data
  };
}

export function getNotificationsError(error) {
  return {
    type: GET_NOTIFICATIONS_ERROR,
    error
  };
}
