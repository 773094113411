const USER_SIGNUP_ACTION = "USER/SIGNUP";

export const USER_SIGNUP_REQUEST = `${USER_SIGNUP_ACTION}/REQUEST`;
export const USER_SIGNUP_SUCCESS = `${USER_SIGNUP_ACTION}/SUCCESS`;
export const USER_SIGNUP_ERROR = `${USER_SIGNUP_ACTION}/ERROR`;
export const USER_SIGNUP_EMAIL_DUPLICATE = `${USER_SIGNUP_ACTION}/EMAIL/DUPLICATE`;

export function userSignUpRequest(payload){
  return{
    type: USER_SIGNUP_REQUEST,
    payload
  }
}

export function userSignUpSuccess(data) {
  return {
    type: USER_SIGNUP_SUCCESS,
    data
  };
}

export function userSignUpError(error) {
  return {
    type: USER_SIGNUP_ERROR,
    error
  };
}

export function userSignUpDuplicateEmail(error) {
  return {
    type: USER_SIGNUP_EMAIL_DUPLICATE,
    error
  };
}

