import React, { Component } from "react";

class CardNotApproved extends Component {

  render() {
    return (
      <div className="modal fade choose-model-type choose-appointment-model card-not-approved" id="card_not_approved" tabIndex="-1" role="dialog" aria-labelledby="card_not_approved" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
              <p className="popup-title mb-4">Problem in processing your payment. <br />Please contact the clinic before trying again.</p>
              <button type="button" className="cmn-btn canx-btn" onClick={() => this.props.backToCards()}>Back to Payment</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CardNotApproved;

