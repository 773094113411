import { postRequest } from ".";

const findUri = "/layouts/access/_find";

function addPatientClinic(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}
export default {
  addPatientClinic,
};
