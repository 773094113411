import { postRequest } from ".";

const findUri = "/layouts/access/_find";

function myAppointment(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}

export default {
  myAppointment,
};
