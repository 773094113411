import { all } from "redux-saga/effects";
import auth from "./auth";
import signup from "./signup";
import profile from "./profile";
import card from "./card";
import clinics from "./clinics";
import appointment from "./appointment";
import payment from "./payment";
import notifications from "./notifications";

export default function* rootSaga() {
  yield all([
    ...auth,
    ...signup,
    ...profile,
    ...card,
    ...clinics,
    ...appointment,
    ...payment,
    ...notifications,
  ]);
}
