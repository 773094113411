export const INVALID_EMAIL_OR_PASSWORD = "Invalid email or password.";
export const SOMETHING_WENT_WRONG = "Something went wrong!";
export const FORGOT_PASSWORD_SUCCESS = "Password Link has been sent";
export const FORGOT_PASSWORD_EMAIL_NOT_FOUND = "Email doesn't exist";
export const CONGRATULATIONS = "Congratulations";
export const EMAIL_ALREADY_IN_USE = "This email is already in use."
export const CONGRATULATIONS_DESC =
  "Your registration has been successful! <br /> Login to book your first treatment.";
export const NO_DATA_FOUND_FROM_POSTCODE =
  "No data was found for the requested postal code";
export const ACCESS_FORBIDDEN = "Access Forbidden! Please try again later.";
export const SESSION_TIMEOUT = "Your session has timed out";

export const NO_CARDS_ADDED = "No cards added.";
export const VISA_DEBIT = "Visa Debit";
export const MASTER_CARD = "Mastercard";
export const AMERICAN_EXPRESS = "American Express";
export const DELETE_CARD_SUCCESS = "Card deleted successfully";

export const NO_CLINIC_ADDED = "No clinic was added";
export const ADD_FIRST_CLINIC = "Let's get started by adding your first clinic";

export const PROFILE_UPDATE_SUCCESS = "Profile updated successfully";
export const IMAGE_UPLOAD_VALID_ERROR =
  "Image should be .jpg, .jpeg or .png. and size should be less then 15 MB";

export const DAY_LIST = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const MONTH_LIST = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
