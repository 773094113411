import { postRequest } from ".";
import { getRequest, postRequest as clinicPostRequest } from "./clinics-axios";

const paymentUri = "/layouts/access/_find"

function createInvoice(payload, authKey = "") {
  return clinicPostRequest(
    paymentUri,
    payload.payload,
    authKey,
    payload.baseUrl,
    {},
  );
}

function createReceipt(payload, authKey = "") {
  return clinicPostRequest(
    paymentUri,
    payload.payload,
    authKey,
    payload.baseUrl,
    {},
  );
}

function initiatePayment(payload, authKey = "") {
  return clinicPostRequest(
    paymentUri,
    payload.payload,
    authKey,
    payload.baseUrl,
    {},
  );
}

function confirmPayment(payload, authKey = "") {
  return clinicPostRequest(
    paymentUri,
    payload.payload,
    authKey,
    payload.baseUrl,
    {},
  );
}

function findReceipt(payload, authKey = "") {
  return postRequest(paymentUri, payload, authKey);
}

function getConfirmation(payload, authKey = "") {
  return postRequest(paymentUri, payload, authKey);
}

function bookFinalSlot(payload, authKey = "") {
  return clinicPostRequest(
    paymentUri,
    payload.payload,
    authKey,
    payload.baseUrl,
    {},
  );
}

function bookSlot(payload, authKey = "") {
  return clinicPostRequest(
    paymentUri,
    payload.payload,
    authKey,
    payload.baseUrl,
    {},
  );
}

function addAppointment(payload, authKey = "") {
  return postRequest(paymentUri, payload, authKey);
}

function addInvoice(payload, authKey = "") {
  return postRequest(paymentUri, payload, authKey);
}

function addReceipt(payload, authKey = "") {
  return postRequest(paymentUri, payload, authKey);
}

export default {
  createInvoice,
  createReceipt,
  initiatePayment,
  confirmPayment,
  findReceipt,
  getConfirmation,
  bookFinalSlot,
  bookSlot,
  addAppointment,
  addInvoice,
  addReceipt,
};
