const FIND_APPOINTMENT_TYPES = "FIND/APPOINTMENT/TYPES";
const FIND_DIARY = "FIND/DIARY";
const FIND_DIARY_DAY = "FIND/DIARY/DAY";
const FIND_TIME_SLOTS = "FIND/TIME/SLOTS";
const FIND_PACKAGES = "FIND/PACKAGES";
const CHECK_TIMESLOTS = "CHECK/TIMESLOTS";
const FIND_PAYMENT_CARDS = "FIND/PAYMENT/CARDS";

export const FIND_APPOINTMENT_TYPES_REQUEST = `${FIND_APPOINTMENT_TYPES}/REQUEST`;
export const FIND_APPOINTMENT_TYPES_SUCCESS = `${FIND_APPOINTMENT_TYPES}/SUCCESS`;
export const FIND_APPOINTMENT_TYPES_ERROR = `${FIND_APPOINTMENT_TYPES}/ERROR`;

export const FIND_DIARY_REQUEST = `${FIND_DIARY}/REQUEST`;
export const FIND_DIARY_SUCCESS = `${FIND_DIARY}/SUCCESS`;
export const FIND_DIARY_ERROR = `${FIND_DIARY}/ERROR`;

export const FIND_DIARY_DAY_REQUEST = `${FIND_DIARY_DAY}/REQUEST`;
export const FIND_DIARY_DAY_SUCCESS = `${FIND_DIARY_DAY}/SUCCESS`;
export const FIND_DIARY_DAY_ERROR = `${FIND_DIARY_DAY}/ERROR`;

export const FIND_TIME_SLOTS_REQUEST = `${FIND_TIME_SLOTS}/REQUEST`;
export const FIND_TIME_SLOTS_SUCCESS = `${FIND_TIME_SLOTS}/SUCCESS`;
export const FIND_TIME_SLOTS_ERROR = `${FIND_TIME_SLOTS}/ERROR`;

export const FIND_PACKAGES_REQUEST = `${FIND_PACKAGES}/REQUEST`;
export const FIND_PACKAGES_SUCCESS = `${FIND_PACKAGES}/SUCCESS`;
export const FIND_PACKAGES_ERROR = `${FIND_PACKAGES}/ERROR`;

export const CHECK_TIMESLOTS_REQUEST = `${CHECK_TIMESLOTS}/REQUEST`;
export const CHECK_TIMESLOTS_SUCCESS = `${CHECK_TIMESLOTS}/SUCCESS`;
export const CHECK_TIMESLOTS_ERROR = `${CHECK_TIMESLOTS}/ERROR`;

export const FIND_PAYMENT_CARDS_REQUEST = `${FIND_PAYMENT_CARDS}/REQUEST`;
export const FIND_PAYMENT_CARDS_SUCCESS = `${FIND_PAYMENT_CARDS}/SUCCESS`;
export const FIND_PAYMENT_CARDS_ERROR = `${FIND_PAYMENT_CARDS}/ERROR`;


export function findAppointmentTypesRequest(payload) {
  return {
    type: FIND_APPOINTMENT_TYPES_REQUEST,
    payload,
  };
}

export function findAppointmentTypesSuccess(data) {
  return {
    type: FIND_APPOINTMENT_TYPES_SUCCESS,
    data,
  };
}

export function findAppointmentTypesError(error) {
  return {
    type: FIND_APPOINTMENT_TYPES_ERROR,
    error,
  };
}

export function findDiaryRequest(payload) {
  return {
    type: FIND_DIARY_REQUEST,
    payload,
  };
}

export function findDiarySuccess(data) {
  return {
    type: FIND_DIARY_SUCCESS,
    data,
  };
}

export function findDiaryError(error) {
  return {
    type: FIND_DIARY_ERROR,
    error,
  };
}

export function findDiaryDayRequest(payload) {
  return {
    type: FIND_DIARY_DAY_REQUEST,
    payload,
  };
}

export function findDiaryDaySuccess(data) {
  return {
    type: FIND_DIARY_DAY_SUCCESS,
    data,
  };
}

export function findDiaryDayError(error) {
  return {
    type: FIND_DIARY_DAY_ERROR,
    error,
  };
}

export function findTimeSlotsRequest(payload) {
  return {
    type: FIND_TIME_SLOTS_REQUEST,
    payload,
  };
}

export function findTimeSlotsSuccess(data) {
  return {
    type: FIND_TIME_SLOTS_SUCCESS,
    data,
  };
}

export function findTimeSlotsError(error) {
  return {
    type: FIND_TIME_SLOTS_ERROR,
    error,
  };
}

export function findPackagesRequest(payload) {
  return {
    type: FIND_PACKAGES_REQUEST,
    payload,
  };
}

export function findPackagesSuccess(data) {
  return {
    type: FIND_PACKAGES_SUCCESS,
    data,
  };
}

export function findPackagesError(error) {
  return {
    type: FIND_PACKAGES_ERROR,
    error,
  };
}

export function checkTimeslotsRequest(payload) {
  return {
    type: CHECK_TIMESLOTS_REQUEST,
    payload,
  };
}

export function checkTimeslotsSuccess(data) {
  return {
    type: CHECK_TIMESLOTS_SUCCESS,
    data,
  };
}

export function checkTimeslotsError(error) {
  return {
    type: CHECK_TIMESLOTS_ERROR,
    error,
  };
}

export function findPaymentCardsRequest(payload) {
  return {
    type: FIND_PAYMENT_CARDS_REQUEST,
    payload,
  };
}

export function findPaymentCardsSuccess(data) {
  return {
    type: FIND_PAYMENT_CARDS_SUCCESS,
    data,
  };
}

export function findPaymentCardsError(error) {
  return {
    type: FIND_PAYMENT_CARDS_ERROR,
    error,
  };
}

