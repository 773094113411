import { postRequest } from ".";

const findUri = "/layouts/access/_find";

function getNotifications(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}

export default {
  getNotifications,
};
