const ADD_CARD_ACTION = "ADD/CARD";
const UPDATE_CARD_ACTION = "UPDATE/CARD";
const DELETE_CARD_ACTION = "DELETE/CARD";

export const ADD_CARD_REQUEST = `${ADD_CARD_ACTION}/REQUEST`;
export const ADD_CARD_SUCCESS = `${ADD_CARD_ACTION}/SUCCESS`;
export const ADD_CARD_ERROR = `${ADD_CARD_ACTION}/ERROR`;

export const UPDATE_CARD_REQUEST = `${UPDATE_CARD_ACTION}/REQUEST`;
export const UPDATE_CARD_SUCCESS = `${UPDATE_CARD_ACTION}/SUCCESS`;
export const UPDATE_CARD_ERROR = `${UPDATE_CARD_ACTION}/ERROR`;

export const DELETE_CARD_REQUEST = `${DELETE_CARD_ACTION}/REQUEST`;
export const DELETE_CARD_SUCCESS = `${DELETE_CARD_ACTION}/SUCCESS`;
export const DELETE_CARD_ERROR = `${DELETE_CARD_ACTION}/ERROR`;

export function addCardRequest(payload) {
  return {
    type: ADD_CARD_REQUEST,
    payload
  };
}

export function addCardSuccess(data) {
  return {
    type: ADD_CARD_SUCCESS,
    data
  };
}

export function addCardError(error) {
  return {
    type: ADD_CARD_ERROR,
    error
  };
}

export function updateCardRequest(payload) {
  return {
    type: UPDATE_CARD_REQUEST,
    payload
  };
}

export function updateCardSuccess(data) {
  return {
    type: UPDATE_CARD_SUCCESS,
    data
  };
}

export function updateCardError(error) {
  return {
    type: UPDATE_CARD_ERROR,
    error
  };
}

export function deleteCardRequest(payload) {
  return {
    type: DELETE_CARD_REQUEST,
    payload
  };
}

export function deleteCardSuccess(data) {
  return {
    type: DELETE_CARD_SUCCESS,
    data
  };
}

export function deleteCardError(error) {
  return {
    type: DELETE_CARD_ERROR,
    error
  };
}


