import { postRequest } from ".";

const findUri = "/layouts/access/_find";

function getPatientClinics(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}

export default {
  getPatientClinics,
};
