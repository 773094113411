import { takeLatest, call, put } from "redux-saga/effects";
import {

  GET_NOTIFICATIONS_REQUEST,
  getNotificationsSuccess,
  getNotificationsError

} from "../actions/notifications";
import notificationsApi from '../api/get-notifications';
import API_CODES from "../config/api-codes";

function getNotifications() {
  return function* (actions) {
    try {
      const { payload } = actions;
      const data = yield call(() => notificationsApi.getNotifications(payload));

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(getNotificationsSuccess(JSON.parse(data?.['response']?.['scriptResult'])));
      } else {
        yield put(getNotificationsError(JSON.parse(data?.['response']?.['scriptResult'])));
      }
    } catch (error) {
      yield put(getNotificationsError(error));
    }
  };
}


export function* profileAuthWatcher() {
  yield takeLatest(GET_NOTIFICATIONS_REQUEST, getNotifications());
}

export default [profileAuthWatcher()];
