import { postRequest, getRequest } from "./clinics-axios";

const findUri = "/layouts/access/_find";
const deleteUri = "/layouts/paymentcard_api/script/deletecard";

function findPaymentCards(payload, authKey = "") {
  return postRequest(findUri, payload.query, authKey, payload.baseUrl);
}

function deletePaymentCard(payload, authKey = "") {
  return getRequest(deleteUri, authKey, payload.queryParams, payload.baseUrl);
}

export default {
  findPaymentCards,
  deletePaymentCard,
};
