import { postRequest } from ".";

const findUri = "/layouts/access/_find";

function forgotPassword(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}
export default {
  forgotPassword,
};
