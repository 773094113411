import { Map } from "immutable";

import {
  
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR

} from "../actions/notifications";

const initialState = Map({
  
  getNotificationsLoading: false,
  getNotificationsData: [],
  getNotificationsError: null,

});

const actionMap = {

  [GET_NOTIFICATIONS_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        getNotificationsLoading: true,
        getNotificationsData: [],
        getNotificationsError: null,
      })
    );
  },
  [GET_NOTIFICATIONS_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        getNotificationsLoading: false,
        getNotificationsData: action.data['response']['data'],
      })
    );
  },
  [GET_NOTIFICATIONS_ERROR]: (state, action) => {
    return state.merge(
      Map({
        getNotificationsLoading: false,
        getNotificationsError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

};

export default function reducer(state = initialState, action) {
  const fn = actionMap[action.type];
  return fn ? fn(state, action) : state;
}
