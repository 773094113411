import { Map } from "immutable";
import { 
    
  CREATE_INVOICE_REQUEST, 
  CREATE_INVOICE_SUCCESS, 
  CREATE_INVOICE_ERROR, 
  
  CREATE_RECEIPT_REQUEST, 
  CREATE_RECEIPT_SUCCESS, 
  CREATE_RECEIPT_ERROR, 
  
  CONFIRM_PAYMENT_REQUEST, 
  CONFIRM_PAYMENT_SUCCESS, 
  CONFIRM_PAYMENT_ERROR, 
  
  GET_CONFORMATION_SUCCESS, 
  GET_CONFORMATION_REQUEST, 
  GET_CONFORMATION_ERROR, 
  
  FIND_RECEIPT_REQUEST, 
  FIND_RECEIPT_SUCCESS, 
  FIND_RECEIPT_ERROR, 

  INITIATE_PAYMENT_REQUEST,
  INITIATE_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_ERROR,

  BOOK_SLOT_FINAL_REQUEST,
  BOOK_SLOT_FINAL_SUCCESS,
  BOOK_SLOT_FINAL_ERROR,

  BOOK_SLOT_REQUEST,
  BOOK_SLOT_SUCCESS,
  BOOK_SLOT_ERROR,

  ADD_APPOINTMENT_REQUEST,
  ADD_APPOINTMENT_SUCCESS,
  ADD_APPOINTMENT_ERROR,

  ADD_PREVIOUS_APPOINTMENT_REQUEST,
  ADD_PREVIOUS_APPOINTMENT_SUCCESS,
  ADD_PREVIOUS_APPOINTMENT_ERROR,

  ADD_INVOICE_REQUEST,
  ADD_INVOICE_SUCCESS,
  ADD_INVOICE_ERROR,

  ADD_RECEIPT_REQUEST,
  ADD_RECEIPT_SUCCESS,
  ADD_RECEIPT_ERROR,

  SELECT_SAVED_CARD
} from "../actions/payment";

const initialState = Map({
  
  createInvoiceLoading: false,
  createInvoiceData: {},
  createInvoiceError: null,

  createReceiptLoading: false,
  createReceiptData: {},
  createReceiptError: null,

  initiatePaymentLoading: false,
  initiatePaymentData: {},
  initiatePaymentError: null,

  confirmPaymentLoading: false,
  confirmPaymentData: {},
  confirmPaymentError: null,

  findReceiptLoading: false,
  findReceiptData: {},
  findReceiptError: null,

  getConformationLoading: false,
  getConformationData: {},
  getConformationError: null,

  bookFinalSlotLoading: false,
  bookFinalSlotData: {},
  bookFinalSlotError: null,

  bookSlotLoading: false,
  bookSlotData: {},
  bookSlotError: null,

  addAppointmentLoading: false,
  addAppointmentData: {},
  addAppointmentError: null,

  addPreviousAppointmentLoading: false,
  addPreviousAppointmentData: {},
  addPreviousAppointmentError: null,


  addInvoiceLoading: false,
  addInvoiceData: {},
  addInvoiceError: null,

  addReceiptLoading: false,
  addReceiptData: {},
  addReceiptError: null,

  selectedSavedCard: null
});

const actionMap = {

  [CREATE_INVOICE_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        createInvoiceLoading: true,
        createInvoiceData: {},
        createInvoiceError: null
      })
    );
  },
  [CREATE_INVOICE_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        createInvoiceLoading: false,
        createInvoiceData: action.data
      })
    );
  },
  [CREATE_INVOICE_ERROR]: (state, action) => {

    return state.merge(
      Map({
        createInvoiceLoading: false,
        createInvoiceData: {},
        createInvoiceError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

  
  [CREATE_RECEIPT_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        createReceiptLoading: true,
        createReceiptData: {},
        createReceiptError: null
      })
    );
  },
  [CREATE_RECEIPT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        createReceiptLoading: false,
        createReceiptData: action.data
      })
    );
  },
  [CREATE_RECEIPT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        createReceiptLoading: false,
        createReceiptData: {},
        createReceiptError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

  [INITIATE_PAYMENT_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        initiatePaymentLoading: true,
        initiatePaymentData: {},
        initiatePaymentError: null
      })
    );
  },
  [INITIATE_PAYMENT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        initiatePaymentLoading: false,
        initiatePaymentData: action.data
      })
    );
  },
  [INITIATE_PAYMENT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        initiatePaymentLoading: false,
        initiatePaymentData: {},
        initiatePaymentError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },


  [CONFIRM_PAYMENT_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        confirmPaymentLoading: true,
        confirmPaymentData: {},
        confirmPaymentError: null
      })
    );
  },
  [CONFIRM_PAYMENT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        confirmPaymentLoading: false,
        confirmPaymentData: action.data
      })
    );
  },
  [CONFIRM_PAYMENT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        confirmPaymentLoading: false,
        confirmPaymentData: {},
        confirmPaymentError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

  [FIND_RECEIPT_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        findReceiptLoading: true,
        findReceiptData: {},
        findReceiptError: null
      })
    );
  },
  [FIND_RECEIPT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        findReceiptLoading: false,
        findReceiptData: action?.data
      })
    );
  },
  [FIND_RECEIPT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        findReceiptLoading: false,
        findReceiptData: {},
        findReceiptError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

  [GET_CONFORMATION_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        getConformationLoading: true,
        getConformationData: {},
        getConformationError: null
      })
    );
  },
  [GET_CONFORMATION_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        getConformationLoading: false,
        getConformationData: action?.data?.['response']?.['data'][0]
      })
    );
  },
  [GET_CONFORMATION_ERROR]: (state, action) => {
    return state.merge(
      Map({
        getConformationLoading: false,
        getConformationData: {},
        getConformationError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

  [BOOK_SLOT_FINAL_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        bookFinalSlotLoading: true,
        bookFinalSlotData: {},
        bookFinalSlotError: null
      })
    );
  },
  [BOOK_SLOT_FINAL_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        bookFinalSlotLoading: false,
        bookFinalSlotData: action?.data?.['response']?.['data'][0]
      })
    );
  },
  [BOOK_SLOT_FINAL_ERROR]: (state, action) => {
    return state.merge(
      Map({
        bookFinalSlotLoading: false,
        bookFinalSlotData: {},
        bookFinalSlotError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

  [BOOK_SLOT_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        bookSlotLoading: true,
        bookSlotData: {},
        bookSlotError: null
      })
    );
  },
  [BOOK_SLOT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        bookSlotLoading: false,
        bookSlotData: action?.data?.['response']?.['data'][0]
      })
    );
  },
  [BOOK_SLOT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        bookSlotLoading: false,
        bookSlotData: {},
        bookSlotError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

  [ADD_APPOINTMENT_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        addAppointmentLoading: true,
        addAppointmentData: {},
        addAppointmentError: null
      })
    );
  },
  [ADD_APPOINTMENT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        addAppointmentLoading: false,
        addAppointmentData: action?.data?.['response']?.['data'][0]
      })
    );
  },
  [ADD_APPOINTMENT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        addAppointmentLoading: false,
        addAppointmentData: {},
        addAppointmentError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

  [ADD_PREVIOUS_APPOINTMENT_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        addPreviousAppointmentLoading: true,
        addPreviousAppointmentData: {},
        addPreviousAppointmentError: null
      })
    );
  },
  [ADD_PREVIOUS_APPOINTMENT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        addPreviousAppointmentLoading: false,
        addPreviousAppointmentData: action?.data?.['response']?.['data'][0]
      })
    );
  },
  [ADD_PREVIOUS_APPOINTMENT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        addPreviousAppointmentLoading: false,
        addPreviousAppointmentData: {},
        addPreviousAppointmentError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },


  [ADD_INVOICE_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        addInvoiceLoading: true,
        addInvoiceData: {},
        addInvoiceError: null
      })
    );
  },
  [ADD_INVOICE_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        addInvoiceLoading: false,
        addInvoiceData: action?.data?.['response']?.['data'][0]
      })
    );
  },
  [ADD_INVOICE_ERROR]: (state, action) => {
    return state.merge(
      Map({
        addInvoiceLoading: false,
        addInvoiceData: {},
        addInvoiceError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },


  
  [ADD_RECEIPT_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        addReceiptLoading: true,
        addReceiptData: {},
        addReceiptError: null
      })
    );
  },
  [ADD_RECEIPT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        addReceiptLoading: false,
        addReceiptData: action?.data?.['response']?.['data'][0]
      })
    );
  },
  [ADD_RECEIPT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        addReceiptLoading: false,
        addReceiptData: {},
        addReceiptError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },
  [SELECT_SAVED_CARD]: (state, action) => {
    return state.merge(
      Map({
        selectedSavedCard: action.card,
      })
    );
  }
};

export default function reducer(state = initialState, action) {
  const fn = actionMap[action.type];
  return fn ? fn(state, action) : state;
}
