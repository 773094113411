export const required = (key) => (value) => {
  if (!value || !value.trim()) {
    return `Please enter ${key}.`;
  }
  return undefined;
};

export const requiredDatepicker = (key) => (value) => {
  if (!value) {
    return `Please select ${key}.`;
  }
  return undefined;
};

export const requiredExpirayDate = (key) => (value) => {
  if (!value || !value.field1 || !value.field2 || !value.field1.trim() || !value.field2.trim()) {
    return `Please enter ${key}.`;
  }
  return undefined;
};

export const minLength = (len) => (value) => {
  if (value && value.length < len) {
    return `Field must contain minimum ${len} or more.`;
  }
  return undefined;
};

export const maxLength = (len) => (value) => {
  if (value && value.length > len) {
    return `Field must contain maximum ${len} or less.`;
  }
  return undefined;
};

export const validEmail = (key) => (value) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  if (value && !regex.test(value)) {
    return `Please enter valid ${key}.`;
  }
  return undefined;
};

export const numberOnly = (key) => (value) => {
  const regex = /^\d+$/;
  
  if (value && !regex.test(value)) {
    return `Invalid ${key}.`;
  }
  return undefined;
};

export const validPassword = (key) => (value) => {
  // eslint-disable-next-line no-useless-escape
  // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/i;
  const regex = /^(?=.{8,})(?=.*[a-z])(?=.*\d)(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/

  if (value && !regex.test(value)) {
    return `Your ${key} must be at must be at least 8 characters and contain a capital letter, number & special character.`;
  }
  return undefined;
};

export const validPhone = (key) => (value) => {
  const regex1 = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/;
  const regex2 = /^\d{10}$/;
  // if (value && !regex.test(value)) {
    if (value && (!regex1.test(value)) && !regex2.test(value)) {
    return `Please enter a valid UK ${key}.`;
  }
  return undefined;
};

export const compareValue = (
  key,
  compareKey,
  compareValue,
  caseSensitive = true
) => (value) => {
  const msg = `Your ${compareKey}s do not match.`;
  if (caseSensitive) {
    if (compareValue !== value) {
      return msg;
    }
  } else {
    if (compareValue.toLocaleLowerCase() !== value.toLocaleLowerCase()) {
      return msg;
    }
  }
  return undefined;
};

export const compareOldPass = (
  key,
  compareKey,
  compareValue,
  caseSensitive = true
) => (value) => {
  const msg = `The ${compareKey} you have entered is incorrect.`;
  if (caseSensitive) {
    if (compareValue !== value) {
      return msg;
    }
  } else {
    if (compareValue.toLocaleLowerCase() !== value.toLocaleLowerCase()) {
      return msg;
    }
  }
  return undefined;
};

export const compareNewPass = (
  key,
  compareKey,
  compareValue,
  caseSensitive = true
) => (value) => {
  const msg = `${compareKey} is same as old one.`;
  if (caseSensitive) {
    if (compareValue === value) {
      return msg;
    }
  } else {
    if (compareValue.toLocaleLowerCase() === value.toLocaleLowerCase()) {
      return msg;
    }
  }
  return undefined;
};

export const minValue = (
  key,
  minPrice,
  productPrice,
  amount
) => (value) => {

  const msg = 'Please enter valid amount.';

    // if (amount < minPrice || productPrice < amount) {
    if (isNaN(amount) || (amount < minPrice || productPrice < amount)) {
      return msg;
    }
  
  return undefined;
};

export const fullPayment = (
  key,
  price,
  amount
) => (value) => {

  const msg = 'Please enter valid amount.';

    if (+price !== +amount) {
      return msg;
    }
  
  return undefined;
};


export const restrictAtTheRateSymbols = (key) => (value) => {
  const regex = /^[^@#]*$/;
  if (value && !regex.test(value)) {
    return `The ${key} cannot contain @ or #.`;
  }
  return undefined;
};

export const allowAlphaHyphenApos = (key) => (value) => {
  const regex = /^[a-zA-Z-']*$/;
  if (value && !regex.test(value)) {
    return `The ${key} can only contain alphabets, hyphen and apostrophe.`;
  }
  return undefined;
};

export const restrictPipeSymbols = (key) => (value) => {
  const regex = /^[^|]*$/;
  if (value && !regex.test(value)) {
    return `The ${key} cannot contain |.`;
  }
  return undefined;
};
