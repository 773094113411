import React, { Component } from "react";
import { reduxForm, Field, reset } from "redux-form";
import $ from "jquery";
import SimpleImage from "../../../../components/SimpleImage/SimpleImage";
import { required } from "../../../../helpers/validators";
import TextField from "../../../../components/SimpleTextField/TextField";
import SimpleSelect from "../../../../components/SimpleSelect/SimpleSelect";
import { Link } from "react-router-dom";

const requiredLocation = required("location");

class MyBookingForm extends Component {

    render(){

        return(
            <form id="step-booking-2" onSubmit={this.props.handleSubmit} >
                    <div className="modal-body">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                    <i className="clinic-logo"><SimpleImage src={this.props.selectedClinic.fieldData && this.props.selectedClinic['fieldData']['clinic|patient::logo']} /></i>
                    <h2 className="popup-title">Choose a treatment</h2>
                    <div className="chose-treatment cmn-form">
                        <Field
                              id="category"
                              name="category"
                              component={SimpleSelect}
                              selectedValue={this.props.appointmentTypes.length && this.props.appointmentTypes[0] }
                              options={this.props.appointmentTypes}
                              label="Select category"
                              validate={[this.categoryValidation]}
                              noOptionsMessage={'We could not find any category'}
                              wrapperClasses={"form-group"}
                              onChange={(event) => this.props.getPractioner(event)}
                        />
                        <Field
                            id="location"
                            name="location"
                            type="text"
                            label="Location"
                            component={TextField}
                            validate={[requiredLocation]}
                            wrapperClasses={"form-group"}
                            fieldClasses={"custom-input-border"}
                            readOnly={true}
                        />
                        <Field
                              id="practioner"
                              name="practioner"
                              component={SimpleSelect}
                              selectedValue={this.props.diaryArray.length && this.props.diaryArray[0]}
                              options={this.props.diaryArray}
                              label="Select practitioner"
                              validate={[this.practionerValidation]}
                              noOptionsMessage={'We could not find any practitioner'}
                              wrapperClasses={"form-group"}
                              onChange={(event) => this.props.getActiveDate(event)}
                        />
                    </div>	
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="cmn-btn" type="submit" >Choose date & time</button> 
                    <Link to="#" className="back-btn" id="back-booking-1" onClick={() => this.backFromForm()}>back</Link>
                </div>
                </form>
        )
    }


    categoryValidation = selectedOption =>{
        if (!selectedOption || !selectedOption.value) {
            return "Please select category";
        }
        
        return undefined;
        
    }

    practionerValidation = selectedOption =>{

        if (!selectedOption || !selectedOption.value) {
            return "Please select practitioner";
        }
        
        return undefined;
        
    }

    backFromForm = () =>{
        this.props.dispatch(reset("my-booking-form"));
        window.$(".my_booking_flow").modal('hide');
        setTimeout(() => {
            window.$("#choose_appointment_card").modal('show');    
        }, 500);
    }

}

MyBookingForm = reduxForm({
    form: "my-booking-form"
})(MyBookingForm);
  

export default MyBookingForm;