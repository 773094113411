import { takeLatest, call, put } from "redux-saga/effects";
import {
  ADD_CARD_REQUEST,
  addCardSuccess,
  addCardError,
  UPDATE_CARD_REQUEST,
  updateCardSuccess,
  updateCardError,
  DELETE_CARD_REQUEST,
  deleteCardSuccess,
  deleteCardError,
} from "../actions/card";
import findApi from "../api/patient-api-find";
import recordsApi from "../api/patient-api-records";
import API_CODES from "../config/api-codes";

function addCard() {
  return function* (actions) {
    try {
      const { payload } = actions;
      const _payload = {
        query: [payload],
        sort: [
          {
            fieldName: "nameLast",
            sortOrder: "ascend",
          },
        ],
      };
      const data = yield call(() => findApi.addCard(_payload));
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(addCardSuccess(data));
      } else {
        yield put(addCardError(data));
      }
    } catch (error) {
      yield put(addCardError(error));
    }
  };
}

function updateCard() {
  return function* (actions) {
    try {
      const { payload, recordId } = actions;
      const _payload = {
        fieldData: payload,
      };
      const data = yield call(() =>
        recordsApi.updateCard(_payload, recordId)
      );
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(updateCardSuccess(data));
      } else {
        yield put(updateCardError(data));
      }
    } catch (error) {
      yield put(updateCardError(error));
    }
  };
}

function deleteCard() {
  return function* (actions) {
    try {
      const { payload } = actions;
      const data = yield call(() => recordsApi.deleteCard(payload));
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(deleteCardSuccess(data));
      } else {
        yield put(deleteCardError(data));
      }
    } catch (error) {
      yield put(deleteCardError(error));
    }
  };
}

export function* profileAuthWatcher() {
  yield takeLatest(ADD_CARD_REQUEST, addCard());
  yield takeLatest(UPDATE_CARD_REQUEST, updateCard());
  yield takeLatest(DELETE_CARD_REQUEST, deleteCard());
}

export default [profileAuthWatcher()];
