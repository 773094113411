export const BASE_URL = "/";
export const routes = {
  BASEPATH: BASE_URL,

  LOGIN: `${BASE_URL}login`,

  FORGOT_PASSWORD: `${BASE_URL}forgot-password`,

  SIGNUP: `${BASE_URL}signup`,

  PROFILE: `${BASE_URL}profile`,

  CLINICS: `${BASE_URL}clinics`,

  APPOINTMENTS: `${BASE_URL}appointments`,

  PAYMENTS: `${BASE_URL}payments`,

  PAYMENT_TOKEN: `${BASE_URL}paymentToken`,
};
