import { postRequest, patchRequest, deleteRequest } from ".";

const recordsUri = "/layouts/patientapi/records";
const updateProfileUri = '/layouts/access/_find'
const uploadImgUri = "/layouts/photo/records"

function updateCard(payload, recordId, authKey = "") {
  return patchRequest(`${recordsUri}/${recordId}`, payload, authKey);
}

function deleteCard(recordId, authKey = "") {
  return deleteRequest(`${recordsUri}/${recordId}`, authKey);
}

function userSignUp(payload, authKey = "") {
  return postRequest(updateProfileUri, payload, authKey);
}

function updateProfile(payload, authKey = "") {
  return postRequest(updateProfileUri, payload, authKey);
}

function updatePassword(payload, authKey = ""){
  return postRequest(updateProfileUri, payload, authKey);
}

function updateProfileImage(payload, recordId, authKey = "") {
  const _headers = { 
    "Content-Type": "multipart/form-data" 
  };
  return postRequest(
    `${uploadImgUri}/${recordId}/containers/photo/1`,
    payload,
    authKey,
    undefined,
    _headers
  );
}

export default {
  updateCard,
  deleteCard,
  userSignUp,
  updateProfile,
  updateProfileImage,
  updatePassword
};
