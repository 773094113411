import { takeLatest, call, put } from "redux-saga/effects";
import {
  USER_LOGIN_REQUEST,
  userLoginSuccess,
  userLoginError,
  USER_FP_REQUEST,
  userFpSuccess,
  userFpError,
  USER_LOGOUT_REQUEST,
  userLogoutSuccess,
  userLogoutError,
} from "../actions/auth";
import scriptsApi from "../api/patient-api-scripts";
import findApi from "../api/patient-api-find";
import API_CODES from "../config/api-codes";
import {
  LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY,
  LOGGED_USER_SESSION_KEY
} from "../config/constants";

function userLogin() {
  return function* (actions) {
    const { payload } = actions;

    try {

      const session_payload = {
        "query": [
          {
            "z1": "1"
          }
        ],
        "script" : "createSession",
        "script.param" : `${payload.email}|${payload.password}`
      }
  
      const create_session = yield call(() => findApi.createSession(session_payload));
      
      if (
        create_session &&
        create_session.messages &&
        create_session.messages[0] &&
        create_session.messages[0].code === API_CODES.SUCCESS &&
        create_session.response &&
        create_session.response.scriptError === API_CODES.SUCCESS
      ) {

        localStorage.setItem(
          LOGGED_USER_SESSION_KEY,
          create_session?.['response']?.['scriptResult']
        );


      const _payload = {
        "query": [
          {
            "z1": "1"
          }
        ],
        "script" : "findPatient",
        "script.param" : create_session?.['response']?.['scriptResult']
      }
      const data = yield call(() => findApi.userLogin(_payload));

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {

        if(
          data &&
          data['response'] &&
          data['response']['scriptResult']  === "No Matches"
        ) {
          yield put(userLoginError(data));
        } else {
          
            yield put(userLoginSuccess(data));
            const userDetails = JSON.parse(data?.['response']?.['scriptResult']).response['data'][0]['fieldData']
            
            userDetails['idPatient'] = create_session?.['response']?.['scriptResult'];

            localStorage.setItem(
              LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY,
              JSON.stringify(userDetails)
            );
        }

      } else {
        yield put(userLoginError(JSON.parse(data?.['response']?.['scriptResult'])));
      }
    } else {
      yield put(userLoginError(create_session));
    }
    } catch (error) {
      yield put(userLoginError(error));
    }
  } 
};

function forgotPassword() {
  return function* (actions) {
    try {      
      const { payload } = actions;
        const data = yield call(() => scriptsApi.forgotPassword(payload));  
          if (
            data &&
            data.messages &&
            data.messages[0] &&
            data.messages[0].code === API_CODES.SUCCESS
          ) {
            yield put(userFpSuccess(data));
          } else {
            yield put(userFpError(data));
          }
      } catch (error) {
      yield put(userFpError(error));
    }
  };
}

function userLogout() {
  return function* (actions) {

    try {

      const { payload } = actions;
        const data = yield call(() => findApi.logout(payload))  
          if (
            data &&
            data.messages &&
            data.messages[0] &&
            data.messages[0].code === API_CODES.SUCCESS
          ) {
            yield put(userLogoutSuccess(data));
          } else {
            yield put(userLogoutError(data));
          }
      } catch (error) {
      yield put(userFpError(error));
    }
  };
}


export function* userAuthWatcher() {
  yield takeLatest(USER_LOGIN_REQUEST, userLogin());
  yield takeLatest(USER_FP_REQUEST, forgotPassword());
  yield takeLatest(USER_LOGOUT_REQUEST, userLogout());
}

export default [userAuthWatcher()];
