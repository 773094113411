import { postRequest, logoutRequest, deleteRequest } from ".";

// const findUri = "/layouts/patientapi/_find";
const findUri = "/layouts/access/_find";
const logoutUri = "/sessions/"

function userLogin(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}

export function findEmail(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}

function addCard(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}

function patientProfile(payload, authKey = "") {
  return postRequest(findUri, payload, authKey);
}

function createSession(payload, authKey = ""){
  return postRequest(findUri, payload, authKey);
}

function logout(payload, authKey = ""){
  return deleteRequest(logoutUri + payload, authKey, undefined, undefined, { logout: true });
}

export default {
  userLogin,
  findEmail,
  addCard,
  patientProfile,
  createSession, 
  logout
};
