import { Map } from "immutable";

import {
  
  GET_PATIENT_CLINICS_REQUEST,
  GET_PATIENT_CLINICS_SUCCESS,
  GET_PATIENT_CLINICS_ERROR,

  GET_ALL_CLINICS_REQUEST,
  GET_ALL_CLINICS_SUCCESS,
  GET_ALL_CLINICS_ERROR,

  GET_SINGLE_CLINIC_REQUEST,
  GET_SINGLE_CLINIC_SUCCESS,
  GET_SINGLE_CLINIC_ERROR,

  GET_SINGLE_NOTIFICATION_CLINIC_REQUEST,
  GET_SINGLE_NOTIFICATION_CLINIC_SUCCESS,
  GET_SINGLE_NOTIFICATION_CLINIC_ERROR,

  ADD_PATIENT_CLINIC_REQUEST,
  ADD_PATIENT_CLINIC_SUCCESS,
  ADD_PATIENT_CLINIC_ERROR,

  GET_APPOINTMENTS_REQUEST,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENTS_ERROR,

  GET_PATIENT_CLINIC_CARDS_REQUEST,
  GET_PATIENT_CLINIC_CARDS_SUCCESS,
  GET_PATIENT_CLINIC_CARDS_ERROR,
  
  SET_PATIENT_CLINIC_CARDS_OBJECT, 
  SET_PATIENT_CLINIC_DELETE_CARD, 
  DELETE_PATIENT_CLINIC_CARD_REQUEST, 
  DELETE_PATIENT_CLINIC_CARD_SUCCESS, 
  DELETE_PATIENT_CLINIC_CARD_ERROR,

  ADD_PATIENT_REQUEST,
  ADD_PATIENT_SUCCESS,
  ADD_PATIENT_ERROR

} from "../actions/clinics";
import { SOMETHING_WENT_WRONG } from "../config/labels";

const initialState = Map({
  
  allClinicLoading: false,
  allClinicData: {},
  allClinics: [],
  allClinicError: null,

  patientClinicLoading: false,
  patientClinicData: {},
  patientClinics: [],
  patientClinicsCardsInfo: {},
  patientClinicDeleteCard: {
    clinicRecordId: 0,
    ID_PaymentCard: 0,
    cardNumber: '',
    baseUrl: '',
    loading: false,
    deleteStatus: false,
    error: null
  },
  patientClinicError: null,

  singleClinicLoading: false,
  singleClinicData: {},
  singleClinicError: null,

  singleClinicNotificationLoading: false,
  singleClinicNotificationData: {},
  singleClinicNotificationError: null,

  addClinicLoading: false,
  addClinicData: {},
  addClinicError: null,

  appointmentLoading: false,
  appointments: [],
  appointmentData: {},
  appointmentError: false,

  addPatientLoading: false,
  addPatientData: null,
  addPatientError: null

});

const actionMap = {

  [GET_PATIENT_CLINICS_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        patientClinicLoading: true,
        patientClinicData: {},
        patientClinics: [],
        patientClinicError: null
      })
    );
  },
  [GET_PATIENT_CLINICS_SUCCESS]: (state, action) => {
    const patientClinicsCardsInfo = addCardsInfoToPatientClinics(action.data['response']['data']);
    return state.merge(
      Map({
        patientClinicLoading: false,
        patientClinics: action.data['response']['data'],
        patientClinicsCardsInfo,
        patientClinicData: action.data,
      })
    );
  },
  [GET_PATIENT_CLINICS_ERROR]: (state, action) => {

    return state.merge(
      Map({
        patientClinicLoading: false,
        patientClinicData: {},
        patientClinicError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : SOMETHING_WENT_WRONG,
      })
    );
  },

  [GET_ALL_CLINICS_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        allClinicLoading: true,
        allClinicData: null,
        allClinics: [],
        allClinicError: null
      })
    );
  },
  [GET_ALL_CLINICS_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        allClinicLoading: false,
        allClinics: action.data['response']['data'],
        allClinicData: action.data,
      })
    );
  },
  [GET_ALL_CLINICS_ERROR]: (state, action) => {

    return state.merge(
      Map({
        allClinicLoading: false,
        allClinicData: null,
        allClinics: [],
        allClinicError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : SOMETHING_WENT_WRONG,
      })
    );
  },

  [GET_SINGLE_CLINIC_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        singleClinicLoading: true,
        singleClinicData: {}
      })
    );
  },
  [GET_SINGLE_CLINIC_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        singleClinicLoading: false,
        singleClinicData: action.data['response']['data'][0]['fieldData'],
      })
    );
  },
  [GET_SINGLE_CLINIC_ERROR]: (state, action) => {
    return state.merge(
      Map({
        singleClinicLoading: false,
        singleClinicError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : SOMETHING_WENT_WRONG,
      })
    );
  },


  [GET_SINGLE_NOTIFICATION_CLINIC_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        singleClinicNotificationLoading: true,
        singleClinicNotificationData: {}
      })
    );
  },
  [GET_SINGLE_NOTIFICATION_CLINIC_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        singleClinicNotificationLoading: false,
        singleClinicNotificationData: action.data['response']['data'][0]['fieldData'],
      })
    );
  },
  [GET_SINGLE_NOTIFICATION_CLINIC_ERROR]: (state, action) => {
    return state.merge(
      Map({
        singleClinicNotificationLoading: false,
        singleClinicNotificationError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : SOMETHING_WENT_WRONG,
      })
    );
  },

  [ADD_PATIENT_CLINIC_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        addClinicLoading: true,
        addClinicData: {},
      })
    );
  },
  [ADD_PATIENT_CLINIC_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        addClinicLoading: false,
        addClinicData: action.data,
      })
    );
  },
  [ADD_PATIENT_CLINIC_ERROR]: (state, action) => {
    return state.merge(
      Map({
        addClinicLoading: false,
        addClinicError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : SOMETHING_WENT_WRONG,
      })
    );
  },

  [GET_APPOINTMENTS_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        appointmentLoading: true,
        appointments: [],
        appointmentData: {}
      })
    );
  },
  [GET_APPOINTMENTS_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        appointmentLoading: false,
        appointmentData: action.data,
        appointments: action.data['response']['data']
      })
    );
  },
  [GET_APPOINTMENTS_ERROR]: (state, action) => {
    return state.merge(
      Map({
        appointmentLoading: false,
        appointmentError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : SOMETHING_WENT_WRONG,
      })
    );
  },

// ADD PATIENT

  [ADD_PATIENT_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        addPatientLoading: true,
        addPatientData: null,
        addPatientError: null
      })
    );
  },
  [ADD_PATIENT_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        addPatientLoading: false,
        addPatientData: action.data,
      })
    );
  },
  [ADD_PATIENT_ERROR]: (state, action) => {
    return state.merge(
      Map({
        addPatientLoading: false,
        addPatientData: null,
        addPatientError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : SOMETHING_WENT_WRONG,
      })
    );
  },


  [GET_PATIENT_CLINIC_CARDS_REQUEST]: (state, action) => {
    const { recordId, isOpen } = action.payload;
    const patientClinicsCardsInfo = state.get('patientClinicsCardsInfo');
    patientClinicsCardsInfo[recordId] = {
      isOpen: isOpen, loading:true, cards: [], error:null
    };
    return state.merge(
      Map({
        patientClinicsCardsInfo: { ...patientClinicsCardsInfo }
      })
    );
  },
  [GET_PATIENT_CLINIC_CARDS_SUCCESS]: (state, action) => {
    const { recordId, data } = action;
    const cards = data.response.data;
    const patientClinicsCardsInfo = state.get('patientClinicsCardsInfo');
    patientClinicsCardsInfo[recordId] = {
      ...patientClinicsCardsInfo[recordId],
      loading:false, cards, error:null
    };
    return state.merge(
      Map({
        patientClinicsCardsInfo: { ...patientClinicsCardsInfo }
      })
    );
  },
  [GET_PATIENT_CLINIC_CARDS_ERROR]: (state, action) => {
    const errorMsg = action.error && action.error.settings && action.error.settings.message
                      ? action.error.settings.message
                      : "No card found!";
    const patientClinicsCardsInfo = state.get('patientClinicsCardsInfo');
    patientClinicsCardsInfo[action.recordId] = {
      ...patientClinicsCardsInfo[action.recordId],
      loading:false, cards: [], error:errorMsg
    };
    return state.merge(
      Map({
        patientClinicsCardsInfo: { ...patientClinicsCardsInfo }
      })
    );
  },
  [SET_PATIENT_CLINIC_CARDS_OBJECT]: (state, action) => {
    const { recordId, payload } = action;
    const patientClinicsCardsInfo = state.get('patientClinicsCardsInfo');
    patientClinicsCardsInfo[recordId] = {
      ...patientClinicsCardsInfo[recordId],
      ...payload
    };
    return state.merge(
      Map({
        ...patientClinicsCardsInfo
      })
    );
  },
  [SET_PATIENT_CLINIC_DELETE_CARD]: (state, action) => {
    const {clinicRecordId, ID_PaymentCard, cardNumber, baseUrl} = action;
    const patientClinicDeleteCard = state.get('patientClinicDeleteCard');
    return state.merge(
      Map({
        patientClinicDeleteCard: {
          ...patientClinicDeleteCard,
          clinicRecordId,
          ID_PaymentCard,
          cardNumber,
          baseUrl
        }
      })
    );
  },
  [DELETE_PATIENT_CLINIC_CARD_REQUEST]: (state, action) => {
    const patientClinicDeleteCard = state.get('patientClinicDeleteCard');
    return state.merge(
      Map({
        patientClinicDeleteCard: {
          ...patientClinicDeleteCard,
          loading: true,
          deleteStatus: false,
          error: null
        }
      })
    );
  },
  [DELETE_PATIENT_CLINIC_CARD_SUCCESS]: (state, action) => {
    const { clinicRecordId, ID_PaymentCard } = action;
    const patientClinicsCardsInfo = state.get('patientClinicsCardsInfo');
    const newCards = removeCardFromPatientClinics(patientClinicsCardsInfo[clinicRecordId].cards, ID_PaymentCard);
    patientClinicsCardsInfo[clinicRecordId] = {
      ...patientClinicsCardsInfo[clinicRecordId],
      cards: newCards
    };
    return state.merge(
      Map({
        patientClinicsCardsInfo: { ...patientClinicsCardsInfo },
        patientClinicDeleteCard: {
          clinicRecordId: 0,
          ID_PaymentCard: 0,
          cardNumber: '',
          baseUrl: '',
          loading: false,
          deleteStatus: true,
          error: null
        }
      })
    );
  },
  [DELETE_PATIENT_CLINIC_CARD_ERROR]: (state, action) => {
    const errorMsg = action.error && action.error.settings && action.error.settings.message
                      ? action.error.settings.message
                      : SOMETHING_WENT_WRONG;
    return state.merge(
      Map({
        patientClinicDeleteCard: {
          clinicRecordId: 0,
          ID_PaymentCard: 0,
          cardNumber: '',
          baseUrl: '',
          loading: false,
          deleteStatus: false,
          error: errorMsg
        }
      })
    );
  },
};

function addCardsInfoToPatientClinics(patientClinics) {
  const cardInfo = {};
  patientClinics.map(o => {
    cardInfo[o.recordId] = {
      isOpen: false, loading:false, cards: [], error:null
    }
    return o;
  });
  return cardInfo;
}

function removeCardFromPatientClinics(cards, ID_PaymentCard) {
  const newCards = [];
  const length = cards.length;
  for (let i = 0; i < length; i++) {
    const card = cards[i];
    if(card.fieldData.ID_PaymentCard !== ID_PaymentCard){
      newCards.push(card);
    }
  }
  return newCards;
}


export default function reducer(state = initialState, action) {
  const fn = actionMap[action.type];
  return fn ? fn(state, action) : state;
}
