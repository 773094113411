import React, { Component, lazy, Suspense, Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { routes } from "./config/routes";

import "./App.css";
import { ToastContainer } from "react-toastify";
import AuthRouter from "./routers/AuthRouter";
import NoAuthRouter from "./routers/NoAuthRouter";
import BasicRouter from "./routers/BasicRouter";
import { connect } from "react-redux";

// import Loader from "./assets/images/loader-small.gif";
import { firebaseApp } from "./helpers/firebase";

const Login = lazy(() => import("./Views/Login/Login"));
const ForgotPassword = lazy(() =>
  import("./Views/ForgotPassword/ForgotPassword")
);
const SignUp = lazy(() => import("./Views/Signup/SignUp"));
const NotFound = lazy(() => import("./Views/NotFound/NotFound"));
const Dashboard = lazy(() => import("./Views/Dashboard/Dashboard"));
const Profile = lazy(() => import("./Views/Profile/Profile"));
const Clinices = lazy(() => import("./Views/Clinics/Clinics"));
const Payment = lazy(() => import("./Views/Payment/Payment"));
const MobilePayment = lazy(() => import("./Views/MobilePayment/MobilePayment"));
const Appointments = lazy(() => import("./Views/Appointments/Appointments"));

class App extends Component {

  componentDidMount() {
    // window.$('#loader').modal('show');
    const firebaseAppObj = firebaseApp.initializeFirebase();
    firebaseAppObj.analytics();
  }

  render() {
    return (
      <Fragment>
        <Suspense fallback={<div>Loading... </div>}>
          <Router>
            <Switch>
              <AuthRouter path={routes.BASEPATH} exact>
                <Dashboard />
              </AuthRouter>
              <AuthRouter path={routes.PROFILE}>
                <Profile />
              </AuthRouter>
              <NoAuthRouter path={routes.LOGIN}>
                <Login />
              </NoAuthRouter>
              <NoAuthRouter path={routes.FORGOT_PASSWORD}>
                <ForgotPassword />
              </NoAuthRouter>
              <NoAuthRouter path={routes.SIGNUP}>
                <SignUp />
              </NoAuthRouter>
              <BasicRouter path={routes.PAYMENT_TOKEN}>
                <MobilePayment />
              </BasicRouter>
              <AuthRouter path={routes.CLINICS}>
                <Clinices />
              </AuthRouter>
              <AuthRouter path={routes.APPOINTMENTS}>
                <Appointments />
              </AuthRouter>
              <AuthRouter path={routes.PAYMENTS}>
                <Payment />
              </AuthRouter>
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </Suspense>
        <ToastContainer autoClose={3000} />
        {/* { this.props.loading ? <div className="modal fade cmn_info my_booking_flow loader" id="loader" tabindex="-1" role="dialog" aria-labelledby="loader" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content part-content">
              <div className="modal-body">
                <i><img src={Loader} /></i>
              </div>
            </div>
          </div>
        </div> : null} */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { appointment } = state;

  return {
    loading: appointment.get("isLoaderLoading"),
  };
};

export default connect(mapStateToProps)(App);
