import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import $ from 'jquery'

import MyBooking from '../../Dashboard/components/MyBooking/MyBooking';

import ChangePasswordModel from '../../ChangePassword/ChangePassword';

import { routes } from '../../../config/routes';
import { CLINIC_SESSION_KEY, CLINIC_TOKEN_LOCAL_STORAGE_KEY, LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY, LOGGED_USER_SESSION_KEY, TOKEN_LOCAL_STORAGE_KEY } from '../../../config/constants';

import { patientPofileRequest, updatePasswordRequest } from '../../../actions/profile';

import Logo from '../../../assets/images/logo.png';
import LogoBlue from '../../../assets/images/logo-blue.png';
import BellActive from '../../../assets/images/bell-active.png';
import UserIcon from '../../../assets/images/user.svg';
import PasswordIcon from '../../../assets/images/password.svg';
import LogoutIcon from '../../../assets/images/logout.svg';
import SimpleImage from '../../../components/SimpleImage/SimpleImage';
import { getNotificationsRequest } from '../../../actions/notifications';
import Scrollbars from 'react-custom-scrollbars';
import { getSingleClinicNotificationRequest } from '../../../actions/clinics';
import { userLogoutRequest } from '../../../actions/auth';
import { SOMETHING_WENT_WRONG } from '../../../config/labels';
import { Toast } from '../../../helpers/toast';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientData: {},
      selectedNtification: {},
      selectedClinic: {},
      selectedFlow: 'payment',
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const payload = {
      idPatient: localStorage.getItem(LOGGED_USER_SESSION_KEY)
    }
    dispatch(patientPofileRequest(payload));

    this.setState({ selectedFlow: "payment" });

    this.props.dispatch(getNotificationsRequest({
      "query": [
        {
          "z1": "1"
        }
      ],
      "script" : "getNotifications",
      "script.param" : localStorage.getItem(LOGGED_USER_SESSION_KEY)
    }))

    document.addEventListener('click', (evnt) => {

      if(evnt.target.className !== "toggle-notifications-slider"){
        if(!evnt.target.className.includes('notification-tab')){
          $(".notification-submenu").slideUp("slow");
        }
      }

      if( evnt.target.className === "cmn-btn reset-header-props"){
        this.setState({ selectedClinic: {} })
      }
      // reset-header-props
    });

  }

  componentWillUnmount(){
    this.setState({ selectedClinic: {}, selectedFlow: '' })
  }

  toggleNotification = () => {
    $(".notification-submenu").slideToggle("slow");
  }

  handleUpdatePassword = (data) => {
    this.props.dispatch(updatePasswordRequest({
      query: [
        {
            z1: 1
        }
      ],
      
      "script": "updatePassword",
      "script.param" : `${localStorage.getItem(LOGGED_USER_SESSION_KEY)}|${data.Oldpassword}|${data.Newpassword}`
      }
    ));
  }

  componentDidUpdate(prevProps) {
    const {
      updatePasswordLoading,
      profileLoading,
      singleClinicNotificationLoading,

      updatePasswordData,
      patientProfileData,
      singleClinicNotificationData,

      logoutLoading,
      logoutSuccess
      
    } = this.props;

    if (!updatePasswordLoading && prevProps.updatePasswordLoading !== updatePasswordLoading) {
      if (updatePasswordData) {
        $("#change_password .close").click();
        Toast({ type: 'success', message: updatePasswordData['response']['scriptResult'] });
      } else {
        this.setState({ password: { password: '' }});
        Toast({ type: 'error', message: updatePasswordData['response']['scriptResult'] });
      }
    }

    if (!profileLoading && prevProps.profileLoading && prevProps.profileLoading !== profileLoading) {
      if (patientProfileData) {

        var patientData = JSON.parse(localStorage.getItem(LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY));
        this.setState({ patientData: { ...patientData, ...patientProfileData } })
      }
    } 

    if (!logoutLoading && prevProps.logoutLoading !== logoutLoading) {
      if (logoutSuccess) {
        localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
        localStorage.removeItem(CLINIC_TOKEN_LOCAL_STORAGE_KEY);
        localStorage.removeItem(LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY);
        localStorage.removeItem(LOGGED_USER_SESSION_KEY);
        localStorage.removeItem(CLINIC_SESSION_KEY);
        this.props.history.push(routes.LOGIN);        
      } else {
        Toast({ type: 'error', message: SOMETHING_WENT_WRONG });
      }
    }

    if(!singleClinicNotificationLoading && prevProps.singleClinicNotificationLoading !== singleClinicNotificationLoading){
      if(singleClinicNotificationData){

        let newState = {}
                
        newState['fieldData'] = singleClinicNotificationData;
        newState['fieldData']['receiptId'] =  this.state.selectedNtification['fieldData']?.["ID_Invoice"];
        newState['fieldData']['ID_Patient'] = this.state.selectedNtification?.['fieldData']?.['patientClinic|invoice::ID_Patient'];
        newState['fieldData']['client|patient::filepath'] = singleClinicNotificationData['client|clinic::filepath']
        newState['fieldData']['clinic|patient::logo'] = singleClinicNotificationData['logo'];
        newState['fieldData']['productPrice'] = this.state.selectedNtification['fieldData']?.['amountDue'];
        newState['fieldData']['client|patient::terms'] = singleClinicNotificationData['client|clinic::terms'];
        newState['fieldData']['depositAmount'] = 20;
        
        this.setState({ selectedClinic: newState });

        setTimeout(() =>{
          $('#step-booking-2').slideUp();
          $('#step-booking-3').slideUp();
          $('#step-booking-4').slideUp();
          $('#step-booking-5').slideUp();
          $('#step-booking-6').slideUp();
          $('#step-booking-7').slideUp();
          $('#step-booking-8').slideUp();
          $('#step-booking-9').slideDown();
          window.$("#book_flow").modal({backdrop: 'static', keyboard: false}, 'show');    
        }, 0)

      }

    }

  }

  openChangePassword = () => {
    this.props.dispatch(initialize("change_password", {}));
  }

  render() {
    const { patientProfileData } = this.props;
    const profilePic = patientProfileData && patientProfileData.photo ? patientProfileData.photo : '';
    return (
      <div id="header" className="header">
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <div className="container">
            <Link className="navbar-brand" to={routes.BASEPATH} onClick={this.handleMenuLinkClick}>
              <img src={Logo} alt="" />
            </Link>
            <div className="header-rt">
              <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="navbar-collapse collapse sidenav" id="navbarCollapse">
                <ul className="navbar-nav">
                  <li className="nav-item close-item">
                    <Link onClick={this.handleMenuLinkClick} to={routes.BASEPATH}><img src={LogoBlue} alt="" /></Link>
                    <Link to="#" className="closebtn" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">&times;</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${this.isActive(routes.BASEPATH)}`} onClick={this.handleMenuLinkClick} to={routes.BASEPATH}>Dashboard</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${this.isActive(routes.APPOINTMENTS)}`} onClick={this.handleMenuLinkClick} to={routes.APPOINTMENTS}>Appointments</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${this.isActive(routes.CLINICS)}`} onClick={this.handleMenuLinkClick} to={routes.CLINICS}>Clinics</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${this.isActive(routes.PAYMENTS)}`} onClick={this.handleMenuLinkClick} to={routes.PAYMENTS}>Payments</Link>
                  </li>
                </ul>
              </div>
              <div className="noti-tab curosor-pointer">
                <Link to="#" className={`${this.props.getNotificationsData?.length ? 'active' : null } notification notification-tab`} onClick={() =>this.toggleNotification()}>
                  <img src={BellActive} alt="" className="toggle-notifications-slider" />
                </Link>
                <div className="notification-submenu" style={{display: "none" }}>
                { this.props.getNotificationsData?.length ? 
                  <Scrollbars autoHeight={true} autoHeightMax={400} autoHide={true} autoHideTimeout={1000} autoHideDuration={500} className="scroll-bar">
                  { 
                    this.props.getNotificationsData.map((notification, index) =>{
                      return(
                        <div className="noti-cnt" key={index}>
                          <div className="noti-lt">
                            { notification['fieldData']['notificationStatus'] ? <span className="due">{notification['fieldData']['notificationStatus']}</span> : null }
                              <p>{notification['fieldData']['notificationMessage']}</p>
                          </div>
                          <div className="noti-rt">
                            <Link to="#" onClick={() => this.getNotificatio(notification)}>PAY NOW</Link>
                          </div>
                        </div>
                      )
                    })
                }
                </Scrollbars> : <div className={" no-notifications"}>No Notifications Available</div> 
              }
              </div>
              </div>

              <div className="user-drop-down dropdown">
                <Link to={routes.BASEPATH} className="dropdown-toggle" id="navbardrop" data-toggle="dropdown">
                  <i><SimpleImage src={profilePic} /></i>
                  <span>{this.state.patientData.nameFirst} {this.state.patientData.nameLast} </span>
                </Link>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to={routes.PROFILE}>
                    <i>
                      <img src={UserIcon} alt="" />
                    </i>
                    <span>Profile</span>
                  </Link>
                  <Link className="dropdown-item" to={routes.BASEPATH} data-toggle="modal" data-target="#change_password" onClick={() => this.openChangePassword()}>
                    <i>
                      <img src={PasswordIcon} alt="" />
                    </i>
                    <span>Change Password</span>
                  </Link>
                  <Link className="dropdown-item" to={'#'} onClick={this.handleLogout}>
                    <i>
                      <img src={LogoutIcon} alt="" />
                    </i>
                    <span>Logout</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <ChangePasswordModel onSubmit={this.handleUpdatePassword} />
        { this.state.selectedClinic && this.state.selectedClinic['fieldData'] ? <MyBooking selectedFlow={this.state.selectedFlow} selectedClinic={this.state.selectedClinic} closePayment={this.closePayment} /> : null}
      </div>
    );
  }

  closePayment = () => {
    this.setState({ selectedClinic : {} });
  }

  getNotificatio = (notification) =>{

    this.setState({ selectedNtification: notification });
    this.toggleNotification();
    this.setState({ selectedClinic: {} });
    this.props.dispatch(getSingleClinicNotificationRequest(notification['fieldData']['idClinic']));

  }

  handleLogout = () => {
    this.props.dispatch(userLogoutRequest(localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)));
  }

  handleMenuLinkClick = () => {
    setTimeout(() => {
      (window).$('.navbar-collapse').collapse('hide');
    }, 100);
    this.setState({ selectedClinic: {} })
  }

  isActive = (menuLink) => {
    if (menuLink === routes.BASEPATH) {
      return this.props.match.path === routes.BASEPATH ? 'active' : '';
    }
    return this.props.match.path.indexOf(menuLink) >= 0 ? 'active' : '';
  }
};

const mapStateToProps = (state) => {

  const { profile, notifications, clinics, auth } = state;

  return {

    updatePasswordLoading: profile.get('updatePasswordLoading'),
    updatePasswordData: profile.get('updatePasswordData'),
    updatePasswordError: profile.get('updatePasswordError'),

    profileLoading: profile.get('profileLoading'),
    profileData: profile.get('profileData'),
    patientProfileData: profile.get('patientProfileData'),

    getNotificationsLoading: notifications.get('getNotificationsLoading'),
    getNotificationsData: notifications.get('getNotificationsData'),

    singleClinicNotificationLoading: clinics.get("singleClinicNotificationLoading"),
    singleClinicNotificationData: clinics.get("singleClinicNotificationData"),

    logoutLoading: auth.get("logoutLoading"),
    logoutSuccess: auth.get("logoutSuccess"),
    logoutError: auth.get("logoutError"),

  }
}

Header = withRouter(Header);
export default connect(mapStateToProps)(Header);
