const PATIENT_PROFILE_ACTION = "PATIENT/PROFILE";
const UPDATE_PROFILE_ACTION = "UPDATE/PROFILE";
const UPDATE_PROFILE_IMAGE_ACTION = "UPDATE/PROFILE/IMAGE";
const UPDATE_PASSWORD_ACTION = "UPDATE/PASSWORD";

export const PATIENT_PROFILE_REQUEST = `${PATIENT_PROFILE_ACTION}/REQUEST`;
export const PATIENT_PROFILE_SUCCESS = `${PATIENT_PROFILE_ACTION}/SUCCESS`;
export const PATIENT_PROFILE_ERROR = `${PATIENT_PROFILE_ACTION}/ERROR`;

export const UPDATE_PROFILE_REQUEST = `${UPDATE_PROFILE_ACTION}/REQUEST`;
export const UPDATE_PROFILE_SUCCESS = `${UPDATE_PROFILE_ACTION}/SUCCESS`;
export const UPDATE_PROFILE_ERROR = `${UPDATE_PROFILE_ACTION}/ERROR`;

export const UPDATE_PROFILE_IMAGE_REQUEST = `${UPDATE_PROFILE_IMAGE_ACTION}/REQUEST`;
export const UPDATE_PROFILE_IMAGE_SUCCESS = `${UPDATE_PROFILE_IMAGE_ACTION}/SUCCESS`;
export const UPDATE_PROFILE_IMAGE_ERROR = `${UPDATE_PROFILE_IMAGE_ACTION}/ERROR`;

export const UPDATE_PASSWORD_REQUEST = `${UPDATE_PASSWORD_ACTION}/REQUEST`;
export const UPDATE_PASSWORD_SUCCESS = `${UPDATE_PASSWORD_ACTION}/SUCCESS`;
export const UPDATE_PASSWORD_ERROR = `${UPDATE_PASSWORD_ACTION}/ERROR`;

export function patientPofileRequest(payload) {
  return {
    type: PATIENT_PROFILE_REQUEST,
    payload,
  };
}

export function patientPofileSuccess(data) {
  return {
    type: PATIENT_PROFILE_SUCCESS,
    data,
  };
}

export function patientPofileError(error) {
  return {
    type: PATIENT_PROFILE_ERROR,
    error,
  };
}

export function updatePofileRequest(payload, recordId) {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload,
    recordId
  };
}

export function updatePofileSuccess(data) {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    data,
  };
}

export function updatePofileError(error) {
  return {
    type: UPDATE_PROFILE_ERROR,
    error,
  };
}

export function updatePofileImageRequest(payload, recordId) {
  return {
    type: UPDATE_PROFILE_IMAGE_REQUEST,
    payload,
    recordId
  };
}

export function updatePofileImageSuccess(data) {
  return {
    type: UPDATE_PROFILE_IMAGE_SUCCESS,
    data,
  };
}

export function updatePofileImageError(error) {
  return {
    type: UPDATE_PROFILE_IMAGE_ERROR,
    error,
  };
}

export function updatePasswordRequest(payload) {
  return {
    type: UPDATE_PASSWORD_REQUEST,
    payload,
  };
}

export function updatePasswordSuccess(data) {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    data,
  };
}

export function updatePasswordError(error) {
  return {
    type: UPDATE_PASSWORD_ERROR,
    error,
  };
}
