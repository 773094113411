import React, { Component, Fragment } from 'react';
import DefaultImg from '../../assets/images/user-img.png';

class SimpleImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  render() {
    const { src, alt, defaultimg } = this.props;
    const { loaded } = this.state;
    let defaultImage = DefaultImg;
    if (defaultimg) {
      defaultImage = defaultimg;
    }
    const imgStyles = loaded ? {} : { display: 'none' };
    return (
      <Fragment>
        {(!loaded) && <img alt="" src={defaultImage} />}
        <img
          {...this.props}
          src={src}
          alt={alt ? alt : ""}
          onLoad={this.handleLoad}
          onError={this.handleError}
          style={imgStyles}
        />
      </Fragment>
    );
  }

  handleLoad = () => {
    this.setState({ loaded: true });
  }

  handleError = () => {
    this.setState({ loaded: false });
  }
}

export default SimpleImage;