import { takeLatest, call, put } from "redux-saga/effects";
import {
  PATIENT_PROFILE_REQUEST,
  patientPofileSuccess,
  patientPofileError,
  UPDATE_PROFILE_REQUEST,
  updatePofileSuccess,
  updatePofileError,
  UPDATE_PROFILE_IMAGE_REQUEST,
  updatePofileImageSuccess,
  updatePofileImageError,
  UPDATE_PASSWORD_REQUEST,
  updatePasswordSuccess,
  updatePasswordError
} from "../actions/profile";
import findApi from "../api/patient-api-find";
import recordsApi from "../api/patient-api-records";
import API_CODES from "../config/api-codes";

function patientProfile() {
  return function* (actions) {
    try {
      const { payload } = actions;

      const _payload = {
        "query": [
          {
            "z1": "1"
          }
        ],
      
        "script" : "findPatient",
        "script.param" : payload?.['idPatient']
      }
      
      const data = yield call(() => findApi.patientProfile(_payload));

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(patientPofileSuccess(JSON.parse(data?.['response']?.['scriptResult'])));
      } else {
        yield put(patientPofileError(JSON.parse(data?.['response']?.['scriptResult'])));
      }
    } catch (error) {
      yield put(patientPofileError(error));
    }
  };
}

function updateProfile() {
  return function* (actions) {
    try {
      const { payload } = actions;

      const _payload = {
        "query": [
          {
            "z1": "1"
          }
        ],
      
        "script" : "editPatient",
        "script.param" : `${payload.nameFirst}|${payload.nameLast}|${payload.email}|${payload.phoneMobile}|${payload.address1}|${payload.address2}|${payload.addressTown}|${payload.addressCounty}|${payload.addressPostcode}|${payload.addressCountry}|${payload.password}|${payload.idSession}`
      }

      const data = yield call(() =>
        recordsApi.updateProfile(_payload)
      );
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(updatePofileSuccess(data));
      } else {
        yield put(updatePofileError(data));
      }
    } catch (error) {
      const { data } = error.data.response;
      yield put(updatePofileError(data));
    }
  };
}

function updateProfileImage() {
  return function* (actions) {
    try {
      const { payload, recordId } = actions;
      const data = yield call(() =>
        recordsApi.updateProfileImage(payload, recordId)
      );
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(updatePofileImageSuccess(data));
      } else {
        yield put(updatePofileImageError(data));
      }
    } catch (error) {
      yield put(updatePofileImageError(error));
    }
  };
}

function updatePassword() {
  return function* (actions) {
    try {
      const { payload } = actions;
      const data = yield call(() =>
        recordsApi.updatePassword(payload)
      );
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(updatePasswordSuccess(data));
      } else {
        yield put(updatePasswordError(data));
      }
    } catch (error) {
      yield put(updatePasswordError(error));
    }
  };
}


export function* profileAuthWatcher() {
  yield takeLatest(PATIENT_PROFILE_REQUEST, patientProfile());
  yield takeLatest(UPDATE_PROFILE_REQUEST, updateProfile());
  yield takeLatest(UPDATE_PROFILE_IMAGE_REQUEST, updateProfileImage());
  yield takeLatest(UPDATE_PASSWORD_REQUEST, updatePassword());
}

export default [profileAuthWatcher()];
