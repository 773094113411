import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleImage from "../../../../components/SimpleImage/SimpleImage";

import Scrollbars from "react-custom-scrollbars";

class TermsPage extends Component {

  constructor(props){
    super(props);
    this.state = {
      enableTerms: false
    }
  }

  // trackScrolling = (e) => {
  //   if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
  //     this.setState({ enableTerms: true })
  //   }
  // };

  render() {
    return (
      <form id={this.props.formId}>
        <div className="modal-body">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <i className="clinic-logo"><SimpleImage src={this.props.selectedClinic?.['fieldData']?.['clinic|patient::logo']} /></i>
          <h2 className="popup-title">Clinic Terms & Conditions</h2>
          <p className="terms-header">Please read and accept the terms and conditions</p>
        </div>
        <div className="terms-content" onScroll={this.trackScrolling}>
          <Scrollbars autoHeight={true} autoHeightMax={300} autoHide={true} autoHideTimeout={1000} autoHideDuration={250} className="scroll-bar scroll-bar-terms">
            {this.props.terms}
          </Scrollbars>
        </div>
        <div className="modal-footer">
          {/* <button type="button" className="cmn-btn" onClick={() => this.state.enableTerms ? this.props.onTermsAccepted() : null }>Agree & Accept</button> */}
          <button type="button" className="cmn-btn" onClick={() => this.props.onTermsAccepted()}>Agree & Accept</button>
          <Link to="#" className="back-btn" onClick={() => this.props.backFromTerms()}>back</Link>
        </div>
      </form>
    )
  }

}

export default TermsPage;