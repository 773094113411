import React, { Component } from "react";
import { Route } from "react-router-dom";

class BasicRouter extends Component {
  render() {
    const { children, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={() => {
          return (
            <div className="main-container">
              <div className="inner-container">{children}</div>
            </div>
          );
        }}
      />
    );
  }
}

export default BasicRouter;
