import { Map } from "immutable";

import {
  
  ADD_CARD_REQUEST,
  ADD_CARD_SUCCESS,
  ADD_CARD_ERROR,

  UPDATE_CARD_REQUEST,
  UPDATE_CARD_SUCCESS,
  UPDATE_CARD_ERROR,

  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_ERROR

} from "../actions/card";

const initialState = Map({
  
  addCardLoading: false,
  addCardData: null,
  addCardError: null,

  updateCardLoading: false,
  updateCardData: null,
  updateCardError: null,

  deleteCardLoading: false,
  deleteCardData: null,
  deleteCardError: null

});

const actionMap = {

  [ADD_CARD_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        addCardLoading: true,
        addCardData: null,
        addCardError: null,
      })
    );
  },
  [ADD_CARD_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        addCardLoading: false,
        addCardData: action.data,
      })
    );
  },
  [ADD_CARD_ERROR]: (state, action) => {
    return state.merge(
      Map({
        addCardLoading: false,
        addCardError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

  [UPDATE_CARD_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        updateCardLoading: true,
        updateCardData: null,
        updateCardError: null,
      })
    );
  },
  [UPDATE_CARD_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        updateCardLoading: false,
        updateCardData: action.data,
      })
    );
  },
  [UPDATE_CARD_ERROR]: (state, action) => {
    return state.merge(
      Map({
        updateCardLoading: false,
        updateCardError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

  [DELETE_CARD_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        deleteCardLoading: true,
        deleteCardData: null,
        deleteCardError: null,
      })
    );
  },
  [DELETE_CARD_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        deleteCardLoading: false,
        deleteCardData: action.data,
      })
    );
  },
  [DELETE_CARD_ERROR]: (state, action) => {
    return state.merge(
      Map({
        deleteCardLoading: false,
        deleteCardError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },


};

export default function reducer(state = initialState, action) {
  const fn = actionMap[action.type];
  return fn ? fn(state, action) : state;
}
