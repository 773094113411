const GET_PATIENT_CLINICS_ACTION = "GET/PATIENT/CLINICS";
const GET_ALL_CLINICS_ACTION = "GET/ALL/CLINICS";
const GET_SINGLE_CLINIC_ACTION = "GET/SINGLE/CLINIC";
const GET_SINGLE_NOTIFICATION_CLINIC_ACTION = "GET/SINGLE/NOTIFICATION/CLINIC";
const ADD_PATIENT_CLINIC_ACTION = "ADD/PATIENT/CLINIC";
const GET_APPOINTMENTS = "GET/APPOINTMENTS";
const GET_PATIENT_CLINIC_CARDS = "GET/PATIENT_CLINIC/CARDS";
const DELETE_PATIENT_CLINIC_CARD = "DELETE/PATIENT_CLINIC/CARD";
const ADD_PATIENT_ACTION = "ADD/PATIENT";

export const GET_PATIENT_CLINICS_REQUEST = `${GET_PATIENT_CLINICS_ACTION}/REQUEST`;
export const GET_PATIENT_CLINICS_SUCCESS = `${GET_PATIENT_CLINICS_ACTION}/SUCCESS`;
export const GET_PATIENT_CLINICS_ERROR = `${GET_PATIENT_CLINICS_ACTION}/ERROR`;

export const GET_ALL_CLINICS_REQUEST = `${GET_ALL_CLINICS_ACTION}/REQUEST`;
export const GET_ALL_CLINICS_SUCCESS = `${GET_ALL_CLINICS_ACTION}/SUCCESS`;
export const GET_ALL_CLINICS_ERROR = `${GET_ALL_CLINICS_ACTION}/ERROR`;

export const GET_SINGLE_CLINIC_REQUEST = `${GET_SINGLE_CLINIC_ACTION}/REQUEST`;
export const GET_SINGLE_CLINIC_SUCCESS = `${GET_SINGLE_CLINIC_ACTION}/SUCCESS`;
export const GET_SINGLE_CLINIC_ERROR = `${GET_SINGLE_CLINIC_ACTION}/ERROR`;

export const GET_SINGLE_NOTIFICATION_CLINIC_REQUEST = `${GET_SINGLE_NOTIFICATION_CLINIC_ACTION}/REQUEST`;
export const GET_SINGLE_NOTIFICATION_CLINIC_SUCCESS = `${GET_SINGLE_NOTIFICATION_CLINIC_ACTION}/SUCCESS`;
export const GET_SINGLE_NOTIFICATION_CLINIC_ERROR = `${GET_SINGLE_NOTIFICATION_CLINIC_ACTION}/ERROR`;

export const ADD_PATIENT_CLINIC_REQUEST = `${ADD_PATIENT_CLINIC_ACTION}/REQUEST`;
export const ADD_PATIENT_CLINIC_SUCCESS = `${ADD_PATIENT_CLINIC_ACTION}/SUCCESS`;
export const ADD_PATIENT_CLINIC_ERROR = `${ADD_PATIENT_CLINIC_ACTION}/ERROR`;

export const GET_APPOINTMENTS_REQUEST = `${GET_APPOINTMENTS}/REQUEST`;
export const GET_APPOINTMENTS_SUCCESS = `${GET_APPOINTMENTS}/SUCCESS`;
export const GET_APPOINTMENTS_ERROR = `${GET_APPOINTMENTS}/ERROR`;

export const GET_PATIENT_CLINIC_CARDS_REQUEST = `${GET_PATIENT_CLINIC_CARDS}/REQUEST`;
export const GET_PATIENT_CLINIC_CARDS_SUCCESS = `${GET_PATIENT_CLINIC_CARDS}/SUCCESS`;
export const GET_PATIENT_CLINIC_CARDS_ERROR = `${GET_PATIENT_CLINIC_CARDS}/ERROR`;
export const SET_PATIENT_CLINIC_CARDS_OBJECT = "SET/PATIENT_CLINIC/CARDS/OBJECT";

export const SET_PATIENT_CLINIC_DELETE_CARD = "SET/PATIENT_CLINIC/DELETE/CARD";
export const DELETE_PATIENT_CLINIC_CARD_REQUEST = `${DELETE_PATIENT_CLINIC_CARD}/REQUEST`;
export const DELETE_PATIENT_CLINIC_CARD_SUCCESS = `${DELETE_PATIENT_CLINIC_CARD}/SUCCESS`;
export const DELETE_PATIENT_CLINIC_CARD_ERROR = `${DELETE_PATIENT_CLINIC_CARD}/ERROR`;

export const ADD_PATIENT_REQUEST = `${ADD_PATIENT_ACTION}/REQUEST`;
export const ADD_PATIENT_SUCCESS = `${ADD_PATIENT_ACTION}/SUCCESS`;
export const ADD_PATIENT_ERROR = `${ADD_PATIENT_ACTION}/ERROR`;

// export const CLINIC_SESSION_REQUEST = `${CLINIC_SESSION}/REQUEST`;
// export const CLINIC_SESSION_SUCCESS = `${CLINIC_SESSION}/SUCCESS`;
// export const CLINIC_SESSION_ERROR = `${CLINIC_SESSION}/ERROR`;

export function getPatientClinicsRequest(payload) {
  return {
    type: GET_PATIENT_CLINICS_REQUEST,
    payload
  };
}

export function getPatientClinicsSuccess(data) {
  return {
    type: GET_PATIENT_CLINICS_SUCCESS,
    data
  };
}

export function getPatientClinicsError(error) {
  return {
    type: GET_PATIENT_CLINICS_ERROR,
    error
  };
}

export function getAllClinicsRequest(payload) {
  return {
    type: GET_ALL_CLINICS_REQUEST,
    payload
  };
}

export function getAllClinicsSuccess(data) {
  return {
    type: GET_ALL_CLINICS_SUCCESS,
    data
  };
}

export function getAllClinicsError(error) {
  return {
    type: GET_ALL_CLINICS_ERROR,
    error
  };
}


export function getSingleClinicRequest(payload) {
  return {
    type: GET_SINGLE_CLINIC_REQUEST,
    payload
  };
}

export function getSingleClinicSuccess(data) {
  return {
    type: GET_SINGLE_CLINIC_SUCCESS,
    data
  };
}

export function getSingleClinicError(error) {
  return {
    type: GET_SINGLE_CLINIC_ERROR,
    error
  };
}


export function getSingleClinicNotificationRequest(payload) {
  return {
    type: GET_SINGLE_NOTIFICATION_CLINIC_REQUEST,
    payload
  };
}

export function getSingleClinicNotificationSuccess(data) {
  return {
    type: GET_SINGLE_NOTIFICATION_CLINIC_SUCCESS,
    data
  };
}

export function getSingleClinicNotificationError(error) {
  return {
    type: GET_SINGLE_NOTIFICATION_CLINIC_ERROR,
    error
  };
}

export function addPatientClinicRequest(payload) {
  return {
    type: ADD_PATIENT_CLINIC_REQUEST,
    payload
  };
}

export function addPatientClinicSuccess(data) {
  return {
    type: ADD_PATIENT_CLINIC_SUCCESS,
    data
  };
}

export function addPatientClinicError(error) {
  return {
    type: ADD_PATIENT_CLINIC_ERROR,
    error
  };
}

export function getAppointmentsRequest(payload) {
  return {
    type: GET_APPOINTMENTS_REQUEST,
    payload
  };
}

export function getAppointmentsSuccess(data) {
  return {
    type: GET_APPOINTMENTS_SUCCESS,
    data
  };
}

export function getAppointmentsError(error) {
  return {
    type: GET_PATIENT_CLINICS_ERROR,
    error
  };
}

export function getPatientClinicCardsRequest(payload) {
  return {
    type: GET_PATIENT_CLINIC_CARDS_REQUEST,
    payload
  };
}

export function getPatientClinicCardsSuccess(recordId, data) {
  return {
    type: GET_PATIENT_CLINIC_CARDS_SUCCESS,
    recordId,
    data
  };
}

export function getPatientClinicCardsError(recordId, error) {
  return {
    type: GET_PATIENT_CLINIC_CARDS_ERROR,
    recordId,
    error
  };
}

export function setPatientClinicCardsObject(recordId, payload) {
  return {
    type: SET_PATIENT_CLINIC_CARDS_OBJECT,
    recordId,
    payload
  };
}

export function setPatientClinicDeleteCard(clinicRecordId, ID_PaymentCard, cardNumber, baseUrl) {
  return {
    type: SET_PATIENT_CLINIC_DELETE_CARD,
    clinicRecordId,
    ID_PaymentCard,
    cardNumber,
    baseUrl
  };
}

export function deletePatientClinicCardRequest(payload) {
  return {
    type: DELETE_PATIENT_CLINIC_CARD_REQUEST,
    payload
  };
}

export function deletePatientClinicCardSuccess(clinicRecordId, ID_PaymentCard, data) {
  return {
    type: DELETE_PATIENT_CLINIC_CARD_SUCCESS,
    clinicRecordId,
    ID_PaymentCard,
    data
  };
}

export function deletePatientClinicCardError(error) {
  return {
    type: DELETE_PATIENT_CLINIC_CARD_ERROR,
    error
  };
}

export function addPatientRequest(payload) {
  return {
    type: ADD_PATIENT_REQUEST,
    payload
  };
}

export function addPatientSuccess(data) {
  return {
    type: ADD_PATIENT_SUCCESS,
    data
  };
}

export function addPatientError(error) {
  return {
    type: ADD_PATIENT_ERROR,
    error
  };
}
