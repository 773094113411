const CREATE_INVOICE = "CREATE/INVOICE";
const CREATE_RECEIPT = "CREATE/RECEIPT";
const INITIATE_PAYMENT = "INITIATE/PAYMENT";
const CONFIRM_PAYMENT = "CONFIRM/PAYMENT";
const BOOK_SLOT = "BOOK/SLOT";
const BOOK_SLOT_FINAL = "BOOK/SLOT/FINAL";
const ADD_APPOINTMENT = "ADD/APPOINTMENT";
const ADD_PREVIOUS_APPOINTMENT = "ADD/PREVIOUS/APPOINTMENT";
const ADD_INVOICE = "ADD/INVOICE";
const ADD_RECEIPT = "ADD/RECEIPT";
const FIND_RECEIPT = "FIND/RECEIPT";
const GET_CONFORMATION = "GET/CONFORMATION";

export const CREATE_INVOICE_REQUEST = `${CREATE_INVOICE}/REQUEST`;
export const CREATE_INVOICE_SUCCESS = `${CREATE_INVOICE}/SUCCESS`;
export const CREATE_INVOICE_ERROR = `${CREATE_INVOICE}/ERROR`;

export const CREATE_RECEIPT_REQUEST = `${CREATE_RECEIPT}/REQUEST`;
export const CREATE_RECEIPT_SUCCESS = `${CREATE_RECEIPT}/SUCCESS`;
export const CREATE_RECEIPT_ERROR = `${CREATE_RECEIPT}/ERROR`;

export const INITIATE_PAYMENT_REQUEST = `${INITIATE_PAYMENT}/REQUEST`;
export const INITIATE_PAYMENT_SUCCESS = `${INITIATE_PAYMENT}/SUCCESS`;
export const INITIATE_PAYMENT_ERROR = `${INITIATE_PAYMENT}/ERROR`;

export const CONFIRM_PAYMENT_REQUEST = `${CONFIRM_PAYMENT}/REQUEST`;
export const CONFIRM_PAYMENT_SUCCESS = `${CONFIRM_PAYMENT}/SUCCESS`;
export const CONFIRM_PAYMENT_ERROR = `${CONFIRM_PAYMENT}/ERROR`;

export const FIND_RECEIPT_REQUEST = `${FIND_RECEIPT}/REQUEST`;
export const FIND_RECEIPT_SUCCESS = `${FIND_RECEIPT}/SUCCESS`;
export const FIND_RECEIPT_ERROR = `${FIND_RECEIPT}/ERROR`;

export const GET_CONFORMATION_REQUEST = `${GET_CONFORMATION}/REQUEST`;
export const GET_CONFORMATION_SUCCESS = `${GET_CONFORMATION}/SUCCESS`;
export const GET_CONFORMATION_ERROR = `${GET_CONFORMATION}/ERROR`;

export const BOOK_SLOT_REQUEST = `${BOOK_SLOT}/REQUEST`;
export const BOOK_SLOT_SUCCESS = `${BOOK_SLOT}/SUCCESS`;
export const BOOK_SLOT_ERROR = `${BOOK_SLOT}/ERROR`;

export const BOOK_SLOT_FINAL_REQUEST = `${BOOK_SLOT_FINAL}/REQUEST`;
export const BOOK_SLOT_FINAL_SUCCESS = `${BOOK_SLOT_FINAL}/SUCCESS`;
export const BOOK_SLOT_FINAL_ERROR = `${BOOK_SLOT_FINAL}/ERROR`;

export const ADD_APPOINTMENT_REQUEST = `${ADD_APPOINTMENT}/REQUEST`;
export const ADD_APPOINTMENT_SUCCESS = `${ADD_APPOINTMENT}/SUCCESS`;
export const ADD_APPOINTMENT_ERROR = `${ADD_APPOINTMENT}/ERROR`;

export const ADD_PREVIOUS_APPOINTMENT_REQUEST = `${ADD_PREVIOUS_APPOINTMENT}/REQUEST`;
export const ADD_PREVIOUS_APPOINTMENT_SUCCESS = `${ADD_PREVIOUS_APPOINTMENT}/SUCCESS`;
export const ADD_PREVIOUS_APPOINTMENT_ERROR = `${ADD_PREVIOUS_APPOINTMENT}/ERROR`;

export const ADD_INVOICE_REQUEST = `${ADD_INVOICE}/REQUEST`;
export const ADD_INVOICE_SUCCESS = `${ADD_INVOICE}/SUCCESS`;
export const ADD_INVOICE_ERROR = `${ADD_INVOICE}/ERROR`;

export const ADD_RECEIPT_REQUEST = `${ADD_RECEIPT}/REQUEST`;
export const ADD_RECEIPT_SUCCESS = `${ADD_RECEIPT}/SUCCESS`;
export const ADD_RECEIPT_ERROR = `${ADD_RECEIPT}/ERROR`;

export const SELECT_SAVED_CARD = "SELECT/SAVED/CARD"


export function createInvoiceRequest(payload) {
  return {
    type: CREATE_INVOICE_REQUEST,
    payload,
  };
}

export function createInvoiceSuccess(data) {
  return {
    type: CREATE_INVOICE_SUCCESS,
    data,
  };
}

export function createInvoiceError(error) {
  return {
    type: CREATE_INVOICE_ERROR,
    error,
  };
}

export function createReceiptRequest(payload) {
  return {
    type: CREATE_RECEIPT_REQUEST,
    payload,
  };
}

export function createReceiptSuccess(data) {
  return {
    type: CREATE_RECEIPT_SUCCESS,
    data,
  };
}

export function createReceiptError(error) {
  return {
    type: CREATE_RECEIPT_ERROR,
    error,
  };
}

export function initiatePaymentRequest(payload) {
  return {
    type: INITIATE_PAYMENT_REQUEST,
    payload,
  };
}

export function initiatePaymentSuccess(data) {
  return {
    type: INITIATE_PAYMENT_SUCCESS,
    data,
  };
}

export function initiatePaymentError(error) {
  return {
    type: INITIATE_PAYMENT_ERROR,
    error,
  };
}

export function confirmPaymentRequest(payload) {
  return {
    type: CONFIRM_PAYMENT_REQUEST,
    payload,
  };
}

export function confirmPaymentSuccess(data) {
  return {
    type: CONFIRM_PAYMENT_SUCCESS,
    data,
  };
}

export function confirmPaymentError(error) {
  return {
    type: CONFIRM_PAYMENT_ERROR,
    error,
  };
}

export function findReceiptRequest(payload) {
  return {
    type: FIND_RECEIPT_REQUEST,
    payload,
  };
}

export function findReceiptSuccess(data) {
  return {
    type: FIND_RECEIPT_SUCCESS,
    data,
  };
}

export function findReceiptError(error) {
  return {
    type: FIND_RECEIPT_ERROR,
    error,
  };
}

export function getConformationRequest(payload) {
  return {
    type: GET_CONFORMATION_REQUEST,
    payload,
  };
}

export function getConformationSuccess(data) {
  return {
    type: GET_CONFORMATION_SUCCESS,
    data,
  };
}

export function getConformationError(error) {
  return {
    type: GET_CONFORMATION_ERROR,
    error,
  };
}

export function bookFinalSlotRequest(payload) {
  return {
    type: BOOK_SLOT_FINAL_REQUEST,
    payload,
  };
}

export function bookFinalSlotSuccess(data) {
  return {
    type: BOOK_SLOT_FINAL_SUCCESS,
    data,
  };
}

export function bookFinalSlotError(error) {
  return {
    type: BOOK_SLOT_FINAL_ERROR,
    error,
  };
}

export function bookSlotRequest(payload) {
  return {
    type: BOOK_SLOT_REQUEST,
    payload,
  };
}

export function bookSlotSuccess(data) {
  return {
    type: BOOK_SLOT_SUCCESS,
    data,
  };
}

export function bookSlotError(error) {
  return {
    type: BOOK_SLOT_ERROR,
    error,
  };
}

export function addPreviousAppointmentRequest(payload) {
  return {
    type: ADD_PREVIOUS_APPOINTMENT_REQUEST,
    payload,
  };
}

export function addPreviousAppointmentSuccess(data) {
  return {
    type: ADD_PREVIOUS_APPOINTMENT_SUCCESS,
    data,
  };
}

export function addPreviousAppointmentError(error) {
  return {
    type: ADD_PREVIOUS_APPOINTMENT_ERROR,
    error,
  };
}

export function addAppointmentRequest(payload) {
  return {
    type: ADD_APPOINTMENT_REQUEST,
    payload,
  };
}

export function addAppointmentSuccess(data) {
  return {
    type: ADD_APPOINTMENT_SUCCESS,
    data,
  };
}

export function addAppointmentError(error) {
  return {
    type: ADD_APPOINTMENT_ERROR,
    error,
  };
}

export function addInvoiceRequest(payload) {
  return {
    type: ADD_INVOICE_REQUEST,
    payload,
  };
}

export function addInvoiceSuccess(data) {
  return {
    type: ADD_INVOICE_SUCCESS,
    data,
  };
}

export function addInvoiceError(error) {
  return {
    type: ADD_INVOICE_ERROR,
    error,
  };
}

export function addReceiptRequest(payload) {
  return {
    type: ADD_RECEIPT_REQUEST,
    payload,
  };
}

export function addReceiptSuccess(data) {
  return {
    type: ADD_RECEIPT_SUCCESS,
    data,
  };
}

export function addReceiptError(error) {
  return {
    type: ADD_RECEIPT_ERROR,
    error,
  };
}

export function selectSavedCard(card = null) {
  return {
    type: SELECT_SAVED_CARD,
    card,
  };
}
