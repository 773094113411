import React, { Fragment } from 'react';

import './TextField.css';

const TextField = (props) => {
  const wrapperClasses = ['simple-text-field-wrapper'];
  const labelClasses = ['simple-text-field-label'];
  const fieldClasses = ['simple-text-field', 'form-control'];
  const errorClasses = ['error-msg'];
  const warningClasses = ['warning-info'];
  if (props.wrapperClasses) {
    wrapperClasses.push(props.wrapperClasses);
  }
  if(props.meta.touched && props.meta.error){
    wrapperClasses.push('error-wrapper');
  }
  if (props.labelClasses) {
    labelClasses.push(props.labelClasses);
  }
  if (props.fieldClasses) {
    fieldClasses.push(props.fieldClasses);
  }
  if (props.errorClasses) {
    errorClasses.push(props.errorClasses);
  }
  if (props.warningClasses) {
    warningClasses.push(props.warningClasses);
  }
  return (
    <Fragment>
      <div
        className={wrapperClasses.join(' ')}
      >
        {props.label && <label
          className={labelClasses.join(' ')}>
          {props.label}
        </label>
        }
        <input
          className={fieldClasses.join(' ')}
          id={props.id}
          type={props.type}
          name={props.name}
          readOnly={ props.readOnly }
          {...props.input}
        />
        {props.meta.touched &&
          (
            (props.meta.error && <p className={errorClasses.join(' ')}>{props.meta.error}</p>) ||
            (props.meta.warning && <p className={warningClasses.join(' ')}>{props.meta.warning}</p>)
          )
        }
      </div>
    </Fragment>
  );
};

export default TextField;