import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_PATIENT_CLINICS_REQUEST,
  getPatientClinicsSuccess,
  getPatientClinicsError,
  GET_ALL_CLINICS_REQUEST,
  getAllClinicsSuccess,
  getAllClinicsError,
  GET_SINGLE_CLINIC_REQUEST,
  getSingleClinicSuccess,
  getSingleClinicError,
  ADD_PATIENT_CLINIC_REQUEST,
  addPatientClinicSuccess,
  addPatientClinicError,
  GET_APPOINTMENTS_REQUEST,
  getAppointmentsSuccess,
  getAppointmentsError,
  GET_PATIENT_CLINIC_CARDS_REQUEST,
  getPatientClinicCardsSuccess,
  getPatientClinicCardsError,
  DELETE_PATIENT_CLINIC_CARD_REQUEST,
  deletePatientClinicCardSuccess,
  deletePatientClinicCardError,
  ADD_PATIENT_REQUEST,
  addPatientSuccess,
  addPatientError,
  GET_SINGLE_NOTIFICATION_CLINIC_REQUEST,
  getSingleClinicNotificationSuccess,
  getSingleClinicNotificationError,
} from "../actions/clinics";
import findApi from "../api/clinic-api-find";
import findPatientApi from "../api/patient-clinic-api-find";
import recordsAddPatient from "../api/patient-clinic-api-records";
import findAppiontments from "../api/patient-appointment-find";
import findPaymentCards from "../api/find-payment-cards-api";
import API_CODES from "../config/api-codes";
import { LOGGED_USER_SESSION_KEY } from "../config/constants";

function getPatientsClinics() {
  return function* (actions) {
    try {
      const { payload } = actions;
      
      // const _payload = {
      //   query: [
      //     {
      //       idPatient: payload,
      //     },
      //   ],
      // };

      const _payload = {
        query: [{ "z1": "1" }],
        "script" : "getPatientClinics",
        "script.param" : localStorage.getItem(LOGGED_USER_SESSION_KEY)
      };

      const data = yield call(() => findPatientApi.getPatientClinics(_payload));

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(getPatientClinicsSuccess(JSON.parse(data?.['response']?.['scriptResult'])));
      } else {
        yield put(getPatientClinicsError(JSON.parse(data?.['response']?.['scriptResult'])));
      }
    } catch (error) {
      yield put(getPatientClinicsError(error));
    }
  };
}

function getAllClinics() {
  return function* (actions) {
    try {
      const { payload } = actions;

      const _payload = {
        query: [{ "z1": "1" }],
        "script" : "clinicWildcard",
        "script.param" : `${localStorage.getItem(LOGGED_USER_SESSION_KEY)}|${payload.search || '*'}`
      };

      const _data = yield call(() => findApi.getAllClinics(_payload));
      const data = JSON.parse(_data['response']['scriptResult'])
      
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(getAllClinicsSuccess(data));
      } else {
        yield put(getAllClinicsError(data));
      }
    } catch (error) {
      yield put(getAllClinicsError(error));
    }
  };
}

function getSingleClinic() {
  return function* (actions) {
    try {
      const { payload } = actions;

      const _payload = {
        "query": [
          {
            "z1": "1"
          }
        ],
        "script" : "getClinic",
        "script.param" : payload
      };
      const _data = yield call(() => findApi.getSingleClinic(_payload));
      const data = JSON.parse(_data['response']['scriptResult'])
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(getSingleClinicSuccess(data));
      } else {
        yield put(getSingleClinicError(data));
      }
    } catch (error) {
      yield put(getAllClinicsError(error));
    }
  };
}

function getSinleNotificationClinic() {
  return function* (actions) {
    try {
      const { payload } = actions;
      
      const _payload = {
        "query": [
          {
            "z1": "1"
          }
        ],
        "script" : "getClinic",
        "script.param" : payload
      };
      const _data = yield call(() => findApi.getSingleClinic(_payload));
      const data = JSON.parse(_data['response']['scriptResult'])
      
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(getSingleClinicNotificationSuccess(data));
      } else {
        yield put(getSingleClinicNotificationError(data));
      }
    } catch (error) {
      yield put(getSingleClinicNotificationError(error));
    }
  };
}

function addPatientClinic() {
  return function* (actions) {
    try {
      const { payload } = actions;
      const data = yield call(() =>
        recordsAddPatient.addPatientClinic(payload)
      );
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(addPatientClinicSuccess(data));
      } else {
        yield put(getSingleClinicError(data));
      }
    } catch (error) {
      yield put(addPatientClinicError(error));
    }
  };
}

function getAppointments() {
  return function* (actions) {
    try {
      const { payload } = actions;
      const data = yield call(() => findAppiontments.myAppointment(payload));
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(getAppointmentsSuccess(JSON.parse(data?.['response']?.['scriptResult'])));
      } else {
        yield put(getAppointmentsError(JSON.parse(data?.['response']?.['scriptResult'])));
      }
    } catch (error) {
      yield put(getAppointmentsError(error));
    }
  };
}

function getPatientClinicCardsRequest() {
  return function* (actions) {
    try {
      const { recordId, patientPayload, baseUrl } = actions.payload;
      const localPayload = {
        query: patientPayload,
        baseUrl,
      };
      const _data = yield call(() =>
        findPaymentCards.findPaymentCards(localPayload)
      );

      const data = JSON.parse(_data?.['response']?.['scriptResult'])

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(getPatientClinicCardsSuccess(recordId, data));
      } else {
        yield put(getPatientClinicCardsError(recordId, data));
      }
    } catch (error) {
      yield put(getPatientClinicCardsError(actions.payload.recordId, error));
    }
  };
}

function deletePatientClinicCardRequest() {
  return function* (actions) {
    try {
      const { clinicRecordId, ID_PaymentCard, baseUrl } = actions.payload;
      const localPayload = {
        queryParams: {
          "script.param": ID_PaymentCard,
        },
        baseUrl,
      };
      const data = yield call(() =>
        findPaymentCards.deletePaymentCard(localPayload)
      );
      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        yield put(
          deletePatientClinicCardSuccess(clinicRecordId, ID_PaymentCard, data)
        );
      } else {
        yield put(deletePatientClinicCardError(data));
      }
    } catch (error) {
      yield put(deletePatientClinicCardError(error));
    }
  };
}

function addPatientRequest() {
  return function* (actions) {
    try {
      const { payload } = actions;

      const _payload = {
        "query": [
          {
            "z1": "1"
          }
        ],
      
        "script" : "createPatient",
        "script.param" : `${payload["payload"]["nameFirst"]}|${payload["payload"]["nameLast"]}|${payload["payload"]["email"]}|${payload["payload"]["phoneMobile"]}|${payload["payload"]["dateOfBirth"]}|${payload["payload"]["address1"]}|${payload["payload"]["address2"]}|${payload["payload"]["addressTown"]}|${payload["payload"]["addressCounty"]}|${payload["payload"]["addressPostcode"]}|${payload["payload"]["idPatient"]}|${payload["payload"]["ID_Clinic"]}`
      }

      const combineData = { payload: _payload, baseUrl: payload.baseUrl }

      const data = yield call(() => findApi.addPatient(combineData));

      if (
        data &&
        data.messages &&
        data.messages[0] &&
        data.messages[0].code === API_CODES.SUCCESS
      ) {
        const {
          response: { scriptResult },
        } = data;
        const {
          payload: { idPatient, idClinic }, 
        } = payload;
        const ID_Patient = scriptResult;

        const combinedPatientPayload = {
          "query": [
            {
              "z1": "1"
            }
          ],
        
          "script" : "updatePatientID",
          "script.param" : `${localStorage.getItem(LOGGED_USER_SESSION_KEY)}|${idClinic}|${ID_Patient}`
        }

        const patientClinicAddPatientRes = yield call(() => findApi.patientClinicAddPatient(combinedPatientPayload));

        if (
          patientClinicAddPatientRes &&
          patientClinicAddPatientRes.messages &&
          patientClinicAddPatientRes.messages[0] &&
          patientClinicAddPatientRes.messages[0].code === API_CODES.SUCCESS
        ) {
          yield put(addPatientSuccess(data));
        } else {
          yield put(addPatientError(data));
        }
      } else {
        yield put(addPatientError(data));
      }
    } catch (error) {
      yield put(addPatientError(error));
    }
  };
}

export function* profileAuthWatcher() {
  yield takeLatest(GET_PATIENT_CLINICS_REQUEST, getPatientsClinics());
  yield takeLatest(GET_ALL_CLINICS_REQUEST, getAllClinics());
  yield takeLatest(GET_SINGLE_CLINIC_REQUEST, getSingleClinic());
  yield takeLatest(ADD_PATIENT_CLINIC_REQUEST, addPatientClinic());
  yield takeLatest(GET_APPOINTMENTS_REQUEST, getAppointments());
  yield takeLatest(
    GET_PATIENT_CLINIC_CARDS_REQUEST,
    getPatientClinicCardsRequest()
  );
  yield takeLatest(
    DELETE_PATIENT_CLINIC_CARD_REQUEST,
    deletePatientClinicCardRequest()
  );
  yield takeLatest(ADD_PATIENT_REQUEST, addPatientRequest());
  yield takeLatest(
    GET_SINGLE_NOTIFICATION_CLINIC_REQUEST,
    getSinleNotificationClinic()
  );
}

export default [profileAuthWatcher()];
