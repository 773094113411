import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import PasswordField from '../../components/PasswordField/PasswordField';
import {
  required,
  restrictAtTheRateSymbols,
  compareValue,
  compareOldPass,
  compareNewPass,
  validPassword
} from '../../helpers/validators';

const requiredPassword = required('password');
const requiredNPassword = required('new password');
const requiredCNPassword = required('confirm password');
const restrictSpecialSymbolsPassword = restrictAtTheRateSymbols('new password');
const selector = formValueSelector('change_password');
const validNewPassword = validPassword('password')

class ChangePasswordModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compareValue: compareValue('confirm password', 'new password', ''),
      compareNewPass: compareNewPass('old password', 'new password', ''),
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.new_password !== this.props.new_password) {
      this.setState({
        compareValue: compareValue('confirm Password', 'new password', this.props.new_password),
        compareNewPass: compareNewPass('old password', 'New password', this.props.old_password)
      })
    }
    if (prevProps.old_password !== this.props.old_password) {
      this.setState({
        compareNewPass: compareNewPass('old password', 'New password', this.props.old_password)
      })
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="modal fade cmn_info change_password" id="change_password" tabIndex="-1" role="dialog" aria-labelledby="change_password" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h2 className="popup-title">Change Password</h2>
                <div className="login-blk resgister-blk cmn-form">
                  <div className="top-form">

                    <Field
                      id="Oldpassword"
                      name="Oldpassword"
                      type="password"
                      component={PasswordField}
                      label="Old Password"
                      validate={[requiredPassword]}
                      wrapperClasses={"form-group"}
                      errorClasses={"color-red"}
                    />
                    <Field
                      id="Newpassword"
                      name="Newpassword"
                      type="password"
                      component={PasswordField}
                      label="New Password"
                      validate={[requiredNPassword, restrictSpecialSymbolsPassword, validNewPassword, this.state.compareNewPass]}
                      wrapperClasses={"form-group"}
                      errorClasses={"color-red"}
                    />
                    <Field
                      id="Confirmpassword"
                      name="Confirmpassword"
                      type="password"
                      component={PasswordField}
                      label="Confirm New Password"
                      validate={[requiredCNPassword, this.state.compareValue]}
                      wrapperClasses={"form-group"}
                      errorClasses={"color-red"}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="cmn-btn">Save</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

}

ChangePasswordModel = reduxForm({
  form: "change_password"
})(ChangePasswordModel);

const mapStateToProps = state => {
  const old_password = selector(state, 'Oldpassword');
  const new_password = selector(state, 'Newpassword');
  return {
    old_password,
    new_password
  };
};

export default connect(mapStateToProps)(ChangePasswordModel);
