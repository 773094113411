import { Map } from "immutable";

import {
  
  PATIENT_PROFILE_REQUEST,
  PATIENT_PROFILE_SUCCESS,
  PATIENT_PROFILE_ERROR,

  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,

  UPDATE_PROFILE_IMAGE_REQUEST,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  UPDATE_PROFILE_IMAGE_ERROR,

  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR
  

} from "../actions/profile";
import API_CODES from "../config/api-codes";
import { SOMETHING_WENT_WRONG } from "../config/labels";

const initialState = Map({
  
  profileLoading: false,
  profileData: null,
  patientProfileData: {},
  patientPaymentCards: [],
  patientInvoices: [],
  patientReceipts: [],
  profileError: null,

  updateProfileLoading: false,
  updateProfileData: null,
  updateProfileError: null,

  updatePasswordLoading: false,
  updatePasswordData: null,
  updatePasswordError: null,

  updateProfileImageLoading: false,
  updateProfileImageData: null,
  updateProfileImageError: null,

});

const actionMap = {

  [PATIENT_PROFILE_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        profileLoading: true,
        profileData: null,
        patientProfileData: null,
        patientInvoices: [],
        patientReceipts: [],
        profileError: null,
      })
    );
  },
  [PATIENT_PROFILE_SUCCESS]: (state, action) => {
    
    return state.merge(
      Map({
        profileLoading: false,
        profileData: action.data,
        patientProfileData: action.data?.response?.data[0]?.fieldData,
        patientPaymentCards: action.data?.response?.data[0]?.portalData?.paymentCards,
        patientInvoices: action.data?.response?.data[0]?.portalData?.invoices,
        patientReceipts: action.data?.response?.data[0]?.portalData?.receipts,
      })
    );
  },
  [PATIENT_PROFILE_ERROR]: (state, action) => {
    return state.merge(
      Map({
        profileLoading: false,
        patientInvoices: [],
        patientReceipts: [],
        profileError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

  [UPDATE_PROFILE_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        updateProfileLoading: true,
        updateProfileData: null,
        updateProfileError: null,
      })
    );
  },
  [UPDATE_PROFILE_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        updateProfileLoading: false,
        updateProfileData: action.data,
      })
    );
  },
  [UPDATE_PROFILE_ERROR]: (state, action) => {
    const { error } = action;
    let errorMsg = SOMETHING_WENT_WRONG;
    if(error && error.messages && error.messages[0] && error.messages[0].message) {
      errorMsg = error.messages[0].message;
      if(error.messages[0].code && error.messages[0].code === API_CODES.NOT_UNIQUE_VALIDATION) {
        errorMsg = 'This email is already in use';
      }
    }
    return state.merge(
      Map({
        updateProfileLoading: false,
        updateProfileError: errorMsg
      })
    );
  },

  [UPDATE_PROFILE_IMAGE_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        updateProfileImageLoading: true,
        updateProfileImageData: null,
        updateProfileImageError: null,
      })
    );
  },
  [UPDATE_PROFILE_IMAGE_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        updateProfileImageLoading: false,
        updateProfileImageData: action.data,
      })
    );
  },
  [UPDATE_PROFILE_IMAGE_ERROR]: (state, action) => {
    return state.merge(
      Map({
        updateProfileImageLoading: false,
        updateProfileImageError:
          action.error && action.error.settings && action.error.settings.message
            ? action.error.settings.message
            : "Something went wrong!",
      })
    );
  },

  [UPDATE_PASSWORD_REQUEST]: (state, action) => {
    return state.merge(
      Map({
        updatePasswordLoading: true,
        updatePasswordData: null,
        updatePasswordError: null,
      })
    );
  },
  [UPDATE_PASSWORD_SUCCESS]: (state, action) => {
    return state.merge(
      Map({
        updatePasswordLoading: false,
        updatePasswordData: action.data,
      })
    );
  },
  [UPDATE_PASSWORD_ERROR]: (state, action) => {
    return state.merge(
      Map({
        updatePasswordLoading: false,
        updatePasswordError: SOMETHING_WENT_WRONG
      })
    );
  },

};

export default function reducer(state = initialState, action) {
  const fn = actionMap[action.type];
  return fn ? fn(state, action) : state;
}
