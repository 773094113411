export const ADDRESS_FETCH_SERVER_RESPONSE_TYPE = "data_formatted";

export const BAD_REQUEST = 400;
export const UNAUTHORIZED_STATUS = 401;
export const COMPONENT_DELAY = 300;
export const COMPONENT_TIMEOUT = 4000;

export const LOGGED_USER_DETAILS_LOCAL_STORAGE_KEY = "user-details";
export const REMEMBER_ME_LOCAL_STORAGE_KEY = "remember-me";
export const TOKEN_LOCAL_STORAGE_KEY = "access-token";
export const CLINIC_TOKEN_LOCAL_STORAGE_KEY = "clinic-access-token";
export const CLINIC_BASE_URL_HEADER_KEY = "clinic-base-url";
export const LOGGED_USER_SESSION_KEY = "session-key";
export const CLINIC_SESSION_KEY = "clinic-session-key";
export const DEFAULT_DATE_FORMAT = "dd/MM/yyyy";
export const CARD_APPROVED = "Approved";

export const MIN_PROFILE_UPLOAD_SIZE = 10240;
export const MAX_PROFILE_UPLOAD_SIZE = 15728640;

export const VISA = "VISA";
export const MC = "MC";
export const MC_FULL = "MasterCard";
export const AXEP = "AXEP";
export const AXEP_FULL = "American Express";
